import VueGtag from 'vue-gtag'
import { useRouter } from 'vue-router'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const router = useRouter()

  nuxtApp.vueApp.use(VueGtag, {
    config: {
      id: config.public.gaId,
    },
  }, router)
})
