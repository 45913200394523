import type { EventHook } from '@vueuse/core'
import type { Lease } from '../leases'
import type { RequestEventHookMap, RequestEvents } from '../../factory'
import HttpFactory from '../../factory'
import type { Case } from '.'

export interface CaseTime { hours: number; seconds: number; minutes: number }

export interface SaveRescheduleCourtData<T> {
  case_ids?: Array<Case['id']>
  lease_id?: Lease['id']
  date: string | Date
  time: T
}

export interface CaseRescheduleCourtDateHookMap extends RequestEventHookMap {
  saved: undefined
}

export interface CaseRescheduleCourtDateEvents extends RequestEvents {
  saved: EventHook<CaseRescheduleCourtDateHookMap['saved']>
}

export class CaseRescheduleCourtDatesModule extends HttpFactory<CaseRescheduleCourtDateHookMap, CaseRescheduleCourtDateEvents> {
  protected $events = {
    saved: createEventHook(),
  }

  async save(params: SaveRescheduleCourtData<string>) {
    const response = await this.call('post', '/cases/reschedule-court-date', params)

    await this.$events.saved.trigger(undefined)

    return response
  }
}
