import type { EventHook } from '@vueuse/core'
import type { RequestEventHookMap, RequestEvents } from '../../factory'
import HttpFactory from '../../factory'
import type { Case } from '.'

export interface CaseTrialTime { hours: number; seconds: number; minutes: number }

export interface SaveChangeTrialDateData<T> {
  case_ids: Array<Case['id']>
  date: string | Date
  time: T
}

export interface CaseChangeTrialDateHookMap extends RequestEventHookMap {
  saved: undefined
}

export interface CaseChangeTrialDateEvents extends RequestEvents {
  saved: EventHook<CaseChangeTrialDateHookMap['saved']>
}

export class CaseChangeTrialDateModule extends HttpFactory<CaseChangeTrialDateHookMap, CaseChangeTrialDateEvents> {
  protected $events = {
    saved: createEventHook(),
  }

  async save(params: SaveChangeTrialDateData<string>) {
    const response = await this.call('post', '/cases/change-trial-date', params)

    await this.$events.saved.trigger(undefined)

    return response
  }
}
