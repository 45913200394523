<script lang="ts" setup>
import type {BadgeType} from './badge'

interface Props {
  type?: BadgeType
  class?: string
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

const props = withDefaults(defineProps<Props>(), {
  type: 'success',
  class: '',
  size: 'sm',
})

const {type} = toRefs(props)

const classes = computed(() => {
  switch (type.value) {
    case 'success':
      return 'bg-green-100 text-green-800'
    case 'danger':
      return 'bg-red-100 text-red-800'
    case 'warning':
      return 'bg-yellow-100 text-yellow-800'
    case 'info':
      return 'bg-blue-100 text-blue-800'
    case 'light':
      return 'bg-gray-100 text-gray-800'
    case 'dark':
      return 'bg-gray-800 text-gray-100'
  }
})

const size = `text-${props.size}`
</script>

<template>
  <span
    :class="`${classes} ${size}`"
    class="inline-flex items-center rounded-md px-2.5 py-0.5 font-medium ">
    <slot/>
  </span>
</template>
