import type { PaginatedParams, SearchableParams, SortableParams } from '../factory'
import HttpFactory from '../factory'

export interface Judge {
  id: string
  name: string
  court_id: string
}

export class JudgesModule extends HttpFactory {
  protected $events = {}

  async list(params: PaginatedParams & SortableParams & SearchableParams) {
    return await this.call<Judge[]>('get', '/judges', params)
  }
}
