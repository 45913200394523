import HttpFactory, {
  PaginatedParams,
  RequestEventHookMap,
  RequestEvents,
  SearchableParams,
  SortableParams
} from "~/repository/factory";
import {Document} from "~/repository/modules";
import type {AxiosInstance} from "axios";
import type {EventHook} from "@vueuse/core";

export interface DocumentHookMap extends RequestEventHookMap {
  touched: undefined
}

export interface DocumentEvents extends RequestEvents {
  touched: EventHook<DocumentHookMap['touched']>
}

export class DocumentsModule extends HttpFactory<DocumentHookMap, DocumentEvents> {
  protected $events = {
    touched: createEventHook(),
  }

  constructor(ax: AxiosInstance) {
    super(ax)
  }

  async list(params: SearchableParams & PaginatedParams & SortableParams) {
    return await this.call<Document[]>('get', '/documents', params)
  }

  async downloadLink(params: { location: string }) {
    return await this.call<string>('get', '/documents/download-link', params)
  }
}