import HttpFactory from '../factory'
import type { Resource } from './resources'

export type Permission = string

export interface IPermission {
  value: Permission
  label: string
}

export interface IPermissionGroup {
  group: string
  resource?: Resource
  permissions: IPermission[]
}

export type AvailablePermissions = IPermissionGroup[]

export class PermissionsModule extends HttpFactory {
  protected $events = {}

  async availablePermissions() {
    return await this.call<AvailablePermissions>('get', '/authorization/available-permissions')
  }
}
