import type { EventHook } from '@vueuse/core'
import type { PaginatedParams, RequestEventHookMap, RequestEvents, SearchableParams, SortableParams } from '../factory'
import HttpFactory from '../factory'
import type { LeaseResident } from './leases'

export interface Resident {
  id: string
  full_name: string
  name_on_lease: boolean
  financially_responsible: boolean
  opt_in_for_digital_communication: boolean
  dob: string
  address_line_1?: string
  address_line_2?: string
  city?: string
  state?: string
  postal_code?: string
  county?: string
  media: Media<'scra-certificate'>[]
  status: string
}

export interface UploadResidentDocument {
  certificate: File
}

export interface ResidentsHookMap extends RequestEventHookMap {
  updated: Resident | LeaseResident
  documentUploaded: Resident['media']
}

export interface ResidentsEvents extends RequestEvents {
  updated: EventHook<ResidentsHookMap['updated']>
  documentUploaded: EventHook<ResidentsHookMap['documentUploaded']>
}

export class ResidentsModule extends HttpFactory<ResidentsHookMap, ResidentsEvents> {
  protected $events = {
    updated: createEventHook(),
    documentUploaded: createEventHook(),
  }

  async list(params: SearchableParams & PaginatedParams & SortableParams) {
    return await this.call<Resident[]>('get', '/residents', params)
  }

  async update(residentId: Resident['id'], params: Partial<Omit<Resident | LeaseResident, 'id'>>) {
    const response = await this.call<Resident | LeaseResident>('post', `/residents/${residentId}`, params)

    await this.$events.updated.trigger(response.data)

    return response
  }

  async uploadDocument(residentId: Resident['id'], params: UploadResidentDocument) {
    const formData = new FormData()
    formData.append('certificate', params.certificate)

    const response = await this.call<Resident['media']>('post', `/residents/${residentId}/documents`, undefined, formData)

    await this.$events.updated.trigger(response.data)

    return response
  }
}
