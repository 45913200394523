import type { EventHook } from '@vueuse/core'
import type { IMessageResponse, PaginatedParams, RequestEventHookMap, RequestEvents, SearchableParams } from '../factory'
import HttpFactory from '../factory'

export interface Notification {
  id: string
  type: string
  data: { body: string; type: string; url?: string }
  read_at?: string
  created_at: string
}

export type ListNotificationsParams = SearchableParams & {
  dismissed?: number
}

export interface NotificationHookMap extends RequestEventHookMap {
  read: Notification
}

export interface NotificationEvents extends RequestEvents {
  read: EventHook<NotificationHookMap['read']>
}

export class NotificationsModule extends HttpFactory<NotificationHookMap, NotificationEvents> {
  protected $events: NotificationEvents = {
    read: createEventHook(),
  }

  async list(params: ListNotificationsParams & PaginatedParams) {
    return await this.call<Notification[], { unread_count: number } & PaginationMeta>('get', '/notifications', params)
  }

  async markAllRead() {
    const response = await this.call<IMessageResponse>('post', '/notifications/mark-all-read')

    return response
  }

  async markRead(id: Notification['id']) {
    const response = await this.call<Notification>('post', `/notifications/${id}/read`)

    await this.$events.read.trigger(response.data)

    return response
  }
}
