import HttpFactory from '../../factory'
import type { Unit } from '../units'
import type { Lease } from './'

export type LeaseUnit = Unit

export class LeaseUnitModule extends HttpFactory {
  protected $events = {}

  async get(leaseId: Lease['id']) {
    return await this.call<LeaseUnit>('get', `/leases/${leaseId}/unit`)
  }
}
