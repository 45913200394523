import type { PaginatedParams, SearchableParams, SortableParams } from '../factory'
import HttpFactory from '../factory'
import type { Resource } from './resources'

export interface AssignedCommunity {
  community: {
    id: string
    address: string
    name: string
  }
  role: {
    id: string
    name: string
    description: string
    resource: Resource
  }
  user: {
    id: string
    first_name: string
    last_name: string
    email: string
  }
}

export interface AssignedCommunityMinified {
  community_id: string
  role_id: string
  user_id: string
}

export interface User {
  id: string
  first_name: string
  last_name: string
  full_name: string
  email: string
  roles: string[]
  extra_permissions: string[]
  deleted_at: string | null
  assigned_communities: AssignedCommunity[]
}

export class UsersModule extends HttpFactory {
  protected $events = {}

  async list(params: PaginatedParams & SortableParams & SearchableParams) {
    return await this.call<User[]>('get', '/users', params)
  }

  async get(id: User['id']) {
    return this.call<User>('get', `/users/${id}`)
  }

  async create(user: User) {
    return this.call<User>('post', '/users', {}, user)
  }

  async update(user: User) {
    return this.call<User>('put', `/users/${user.id}`, {}, user)
  }

  async remove(id: User['id']) {
    return this.call<User>('delete', `/users/${id}`)
  }

  async reinstate(id: User['id']) {
    return this.call('put', `/users/${id}/reinstate`)
  }

  async sendWelcomeEmail(userIds: User['id'][]) {
    return this.call('post', '/users/send-welcome-email', {}, { user_ids: userIds })
  }
}
