import type { EventHook } from '@vueuse/core'
import type { RequestEventHookMap, RequestEvents } from '../../factory'
import HttpFactory from '../../factory'
import type { User } from '../users'
import type { Case } from './'

export interface CaseWatch {
  id: string
  is_watched: boolean
  watches: Array<{
    user: User
  }>
}

export interface WatchHookMap extends RequestEventHookMap {
  watched: CaseWatch
  unwatched: CaseWatch
}

export interface WatchEvents extends RequestEvents {
  watched: EventHook<WatchHookMap['watched']>
  unwatched: EventHook<WatchHookMap['unwatched']>
}

export class CaseWatchesModule extends HttpFactory<WatchHookMap, WatchEvents> {
  protected $events = {
    watched: createEventHook(),
    unwatched: createEventHook(),
  }

  async get(caseId: Case['id']) {
    return await this.call<CaseWatch>('get', `/cases/${caseId}/watches`)
  }

  async watch(caseId: Case['id']) {
    const response = await this.call<CaseWatch>('post', `/cases/${caseId}/watches`)

    await this.$events.watched.trigger(response.data)

    return response
  }

  async unwatch(caseId: Case['id']) {
    const response = await this.call<CaseWatch>('delete', `/cases/${caseId}/watches`)

    await this.$events.watched.trigger(response.data)

    return response
  }
}
