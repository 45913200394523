import type { EventHook } from '@vueuse/core'
import type { PaginatedParams, RequestEventHookMap, RequestEvents, SearchableParams, SortableParams } from '../../factory'
import HttpFactory from '../../factory'
import type { Lease } from './'

export interface LeaseDocument {
  id: string
  type: string
  url: string
  name: string
  created_at: string
}

export interface CreateLeaseDocument {
  document_type: string
  document: File
}

export interface DocumentHookMap extends RequestEventHookMap {
  created: LeaseDocument
  deleted: undefined
}

export interface DocumentEvents extends RequestEvents {
  created: EventHook<DocumentHookMap['created']>
  deleted: EventHook<DocumentHookMap['deleted']>
}

export class LeaseDocumentsModule extends HttpFactory<DocumentHookMap, DocumentEvents> {
  protected $events = {
    created: createEventHook(),
    deleted: createEventHook(),
  }

  async list(leaseId: Lease['id'], params: PaginatedParams & SearchableParams & SortableParams) {
    return await this.call<LeaseDocument[]>('get', `/leases/${leaseId}/documents`, params)
  }

  async create(leaseId: Lease['id'], params: CreateLeaseDocument) {
    const formData = new FormData()
    formData.append('document', params.document)
    formData.append('document_type', params.document_type)

    const response = await this.call<LeaseDocument>('post', `/leases/${leaseId}/documents`, undefined, formData)

    await this.$events.created.trigger(response.data)

    return response
  }

  async delete(leaseId: Lease['id'], documentId: LeaseDocument['id']) {
    const response = await this.call('delete', `/leases/${leaseId}/documents/${documentId}`)

    await this.$events.deleted.trigger(undefined)

    return response
  }
}
