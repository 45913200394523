import HttpFactory from '../factory'
import type { Permission } from './permissions'

export type Resource = string

export interface IResource {
  value: Resource
  label: string
  permissions: Permission[]
}

export type AvailableResources = IResource[]

export class ResourcesModule extends HttpFactory {
  protected $events = {}

  async availableResources() {
    return await this.call<AvailableResources>('get', '/authorization/available-role-resources')
  }
}
