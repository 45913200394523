import validate from "/buddy/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.46_encoding@0.1.13_eslint@8.57.0_ioredis@_pp4o25ouglwi7stoinaaykwgka/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/buddy/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.46_encoding@0.1.13_eslint@8.57.0_ioredis@_pp4o25ouglwi7stoinaaykwgka/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "validate-workflow-step": () => import("/buddy/apps/rcm-frontend/sites/dashboard/middleware/validate-workflow-step.ts"),
  auth: () => import("/buddy/apps/rcm-frontend/sites/auth/middleware/auth.ts"),
  guest: () => import("/buddy/apps/rcm-frontend/sites/auth/middleware/guest.ts")
}