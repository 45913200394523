import type { EventHook } from '@vueuse/core'
import type { PaginatedParams, RequestEventHookMap, RequestEvents, SearchableParams } from '../factory'
import HttpFactory from '../factory'
import type { Community } from './communities'
import type { Court } from './courts'
import type { Unit } from './units'

export interface Building {
  id: string
  address_line_1: string
  address_line_2: string | null
  city: string
  state: string
  postal_code: string
  units_count: number
  created_at: string
  community: Community
  court?: Court
  settings: {
    year_built: number
    lead_certificate_settings: {
      lead_exempt: boolean
      lead_free: boolean
      lead_certificate_number?: string
      tenant_refused?: boolean
      lead_cert_expiration: string
    }
    registration_settings: {
      required_to_be_registered: boolean
      registered: boolean
      registration_number?: string
      license_expiration_date?: string
    }
  }
  media: Media<'lead-certificate' | 'registration-document'>[]
}

export interface UploadLeadCertificateDocument {
  lead_certificate: File
}
export interface UploadRegistrationDocument {
  registration: File
}

export interface BuildingsHookMap extends RequestEventHookMap {
  documentUploaded: Building['media']
}

export interface BuildingsEvents extends RequestEvents {
  documentUploaded: EventHook<BuildingsHookMap['documentUploaded']>
}

export class BuildingsModule extends HttpFactory<BuildingsHookMap, BuildingsEvents> {
  protected $events = {
    documentUploaded: createEventHook(),
  }

  async list(params: PaginatedParams & SearchableParams) {
    return this.call<Building[]>('get', '/buildings', params)
  }

  async get(id: Building['id']) {
    return this.call<Building>('get', `/buildings/${id}`)
  }

  async listUnits(id: Building['id'], params: PaginatedParams & SearchableParams) {
    return this.call<Unit[]>('get', `/buildings/${id}/units`, params)
  }

  async update(building: Partial<Building>) {
    return this.call<Building>('put', `/buildings/${building.id}`, undefined, building)
  }

  async updateSettings(buildingId: Building['id'], settings: Partial<Building['settings']>) {
    return this.call<Building>('put', `/buildings/${buildingId}/settings`, undefined, settings)
  }

  async uploadLeadCertificate(buildingId: Building['id'], params: UploadLeadCertificateDocument) {
    const formData = new FormData()
    formData.append('lead_certificate', params.lead_certificate)

    const response = await this.call<Building['media']>('post', `/buildings/${buildingId}/documents`, undefined, formData)

    await this.$events.documentUploaded.trigger(response.data)

    return response
  }

  async uploadRegistrationDocument(buildingId: Building['id'], params: UploadRegistrationDocument) {
    const formData = new FormData()
    formData.append('registration_document', params.registration)

    const response = await this.call<Building['media']>('post', `/buildings/${buildingId}/documents`, undefined, formData)

    await this.$events.documentUploaded.trigger(response.data)

    return response
  }
}
