import type {AxiosInstance} from 'axios'
import type {PaginatedParams, SearchableParams, SortableParams} from '../../../factory'
import type {Integration} from '../'
import HttpFactory from '~/repository/factory'

export interface IntegrationLogResult {
  success: boolean
  successful_records?: number
  failed_records?: number
  errors?: Array<string>
  messages?: Array<string>
  elapsed?: number
}

export interface IntegrationLog {
  id: string
  integration: Integration
  action: string
  result?: IntegrationLogResult
  params?: { [key: string]: any }
  data: Array<any>
  data_processed: boolean
  created_at: string
}

export class IntegrationLogsModule extends HttpFactory {
  protected $events = {}

  constructor(ax: AxiosInstance) {
    super(ax)
  }

  async list(params: PaginatedParams & SearchableParams & SortableParams) {
    return await this.call<IntegrationLog[]>('get', '/logs/integrations', params)
  }

  async get(id: IntegrationLog['id']) {
    return await this.call<IntegrationLog>('get', `/logs/integrations/${id}`)
  }

  async remove(id: IntegrationLog['id']) {
    return await this.call<IntegrationLog>('delete', `/logs/integrations/${id}`)
  }

  async removeMany(ids: Array<IntegrationLog['id']>) {
    return await this.call<IntegrationLog>('delete', '/logs/integrations', {ids})
  }

  async retry(id: IntegrationLog['id']) {
    return await this.call<IntegrationLog>('post', `/logs/integrations/${id}/retry`)
  }
}
