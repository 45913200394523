import type { AxiosInstance } from 'axios'
import type { EventHook } from '@vueuse/core'
import type { RequestEventHookMap, RequestEvents } from '../factory'
import HttpFactory from '../factory'

export interface Firm {
  id: string
  origin_id: string
  origin_platform: string
  name: string
  address_line_1: string
  address_line_2: string
  city: string
  state: string
  postal_code: string
  country: string
  phone: string
  is_individual: boolean
  is_agency: boolean
  updated_at: string
}

export interface FirmHookMap extends RequestEventHookMap {
  updated: Firm
}

export interface FirmEvents extends RequestEvents {
  updated: EventHook<Firm>
}

export class FirmsModule extends HttpFactory<FirmHookMap, FirmEvents> {
  protected $events = {
    updated: createEventHook(),
  }

  constructor(ax: AxiosInstance) {
    super(ax)
  }

  async list() {
    return await this.call<Firm[]>('get', '/firms')
  }

  async getById(id: Firm['id']) {
    return await this.call<Firm>('get', `/firms/${id}`)
  }
}
