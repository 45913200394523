import type { EventHook } from '@vueuse/core'
import HttpFactory, { type RequestEventHookMap, type RequestEvents } from '../factory'

/** TODO - our api should return a normalized type instead of a name... */
export enum WorkflowStepType {
  FilingQueueWrongfulContainer = 'Filing Queue - Wrongful Detainer',
  FilingQueueBreach = 'Filing Queue - Breach',
  FilingQueueResidentHoldingOver = 'Filing Queue - Resident Holding Over',
  FilingQueue = 'Filing Queue',
  Review = '115 Review',
  Sent = '115 Sent',
  DODCheck = 'DOD Check',
  NextQueue = 'Next Queue',
  PrintQueue = 'Print Queue',
  EFileQueue = 'E-File Queue',
  TrialQueue = 'Trial Queue',
  Docket = 'Docket',
  Escrow = 'Escrow',
  Dismissed = 'Dismissed',
  CaseContinuance = 'Case Continuance',
  JudgementLandlord = 'Judgement Landlord',
  JudgementTenant = 'Judgement Tenant',
  WOREligible = 'WOR Eligible',
  WORPrintQueue = 'WOR Print Queue',
  WORSubmitted = 'WOR Submitted',
  EvictionApproved = 'Eviction Approved',
  EvictionNotice = 'Eviction Notice',
  EvictionPending = 'Eviction Pending',
  EvictionComplete = 'Eviction Complete',
  EvictionOther = 'Eviction Other',
  Triage = 'Triage',
  Cancelled = 'Cancelled',
  Archive = 'Archive',
}

export interface WorkflowStep {
  id: string
  name: WorkflowStepType
  step: number
  case_count: number
  case_no_payments_count: number
  case_payments_applied_count: number
  case_ineligible_count: number
  case_escrow_count: number
  case_bankruptcy_count: number
  case_amended_count: number
  case_dismissed_count: number
  requires_processing: boolean
}

export interface Workflow {
  id: string
  name: string
  steps: WorkflowStep[]
  cases_awaiting_processing: number
  created_at: string
}

export interface WorkflowHookMap extends RequestEventHookMap {
  updated: undefined
}

export interface WorkflowEvents extends RequestEvents {
  updated: EventHook<WorkflowHookMap['updated']>
}

export class WorkflowModule extends HttpFactory<WorkflowHookMap, WorkflowEvents> {
  public $events = {
    updated: createEventHook(),
  }

  async get() {
    return await this.call<Workflow>('get', '/workflow')
  }

  async updateSteps(data: any) {
    return await this.call<Workflow>('post', `/workflow/steps`, undefined, data)
  }
}
