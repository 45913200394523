import type { AxiosInstance } from 'axios'
import type { EventHook } from '@vueuse/core'
import type { RequestEventHookMap, RequestEvents } from '../factory'
import HttpFactory from '../factory'
import { OrganizationChartOfAccountsModule } from './organizations/chartOfAccounts'

export enum OrganizationSettingsTab {
  General = 'General',
  CaseFilings = 'Case Filings',
  CaseAutomations = 'Case Automations',
  MultiStepProcessing = 'Multi Step Processing',
  ChartOfAccounts = 'Chart of Accounts',
  LeaseDateConfigurations = 'Lease Date Configurations',
  RolesPermissions = 'Roles & Permissions',
  DemoSettings = 'Demo Settings',
  EFileSettings = 'E-File Settings'
}

export interface OrganizationNoticePeriod {
  id: string
  days: number
}

export interface OrganizationSettings {
  days: number
  frr_test: boolean
  late_fee: number
  allow_efile: boolean
  sync_leases: boolean
  sync_ledger: boolean
  sync_residents: boolean
  sync_properties: boolean
  late_fee_type: 'percentage'
  notice_dc_cv_115: string
  notice_intent_to_file: string
  wor_notice_period: OrganizationNoticePeriod[]
  case_value_rent_only: boolean
  rentworx_scheduling: boolean
  general: OrganizationGeneralSettings
  case_filing: OrganizationCaseFilingSettings
  case_automation_settings: OrganizationCaseAutomationSettings
  demo_settings: OrganizationDemoSettings
}

export interface AutomationSettings { duration: number; duration_type: string; enabled: boolean }

export interface OrganizationCaseAutomationSettings {
  move_115_sent_cases_to_cancelled: AutomationSettings
  move_115_sent_cases_to_filing_queue: AutomationSettings
  move_dismissed_cases_to_archived: AutomationSettings
  move_eviction_approved_cases_to_eviction_notice: AutomationSettings
  move_eviction_completed_cases_to_eviction_to_archive: AutomationSettings
  move_eviction_notice_cases_to_eviction_to_eviction_pending: AutomationSettings
  move_filing_queue_cases_to_cancelled: AutomationSettings
  move_judgement_landlord_cases_to_wor_eligible: AutomationSettings
  move_judgement_tenant_cases_to_archive: AutomationSettings
}

export interface OrganizationGeneralSettings {
  rentworx: boolean
  sync: boolean
  sync_with_external_accounting: boolean
  internal_ledger: boolean
  multi_step_processing: boolean
  enable_sub_ledger: boolean
}

export interface OrganizationCaseFilingSettings {
  bulk_filing_batch_size: number
  case_outstanding_balance_type: 'percentage' | 'fixed'
  case_grace_period: number
  case_judgement_landlord_appeal_period: number
  case_outstanding_balance: number
  enable_late_fee: boolean
  ftpr_batch_prefix: string
  wor_batch_prefix: string
  archive_judgement_tenant_cases_after: number
  frr_test: boolean
  automate_eviction_notice_letters: boolean
  notice_dc_cv_115_summary: string[]
  notice_dc_cv_115_pdf: string[]
  notice_eviction_notice_letters: string[]
  rent_recurrence_frequency: "monthly" | "weekly" | "biweekly"
  rent_recurrence_day: number
}

export interface OrganizationDemoSettings {
  mock_workflow_data: boolean
}

export interface Organization {
  id: string
  name: string
  address_line_1: string
  address_line_2: string
  city: string
  state: string
  postal_code: string
  county: string
  phone: string
  fax: string
  email: string
  settings: OrganizationSettings
  tyler_settings: TylerSettings
  ledger_updated_at: string
  document_types: string[]
  updated_at: string
  created_at: string
}

export interface TylerSettings {}

export interface OrganizationHookMap extends RequestEventHookMap {
  updated: Organization
}

export interface OrganizationEvents extends RequestEvents {
  updated: EventHook<Organization>
}

export class OrganizationModule extends HttpFactory<OrganizationHookMap, OrganizationEvents> {
  protected $events = {
    updated: createEventHook(),
  }

  chart_of_accounts: OrganizationChartOfAccountsModule

  constructor(ax: AxiosInstance) {
    super(ax)
    this.chart_of_accounts = new OrganizationChartOfAccountsModule(ax)
  }

  async get() {
    return await this.call<Organization>('get', '/organization')
  }

  async list() {
    return await this.call<Pick<Organization, 'id' | 'name'>[]>('get', '/organizations')
  }

  async getById(id: Organization['id']) {
    return await this.call<Organization>('get', `/organizations/${id}`)
  }

  async updateGeneralSettings(params: any) {
    const response = await this.call<Organization>('post', '/settings/general', undefined, params)

    this.$events.updated.trigger(response.data)

    return response
  }

  async updateDemoSettings(params: any) {
    const response = await this.call<Organization>('post', '/settings/demo-settings', undefined, params)

    this.$events.updated.trigger(response.data)

    return response
  }


  async updateCaseFilingSettings(params: any) {
    const response = await this.call<Organization>('post', '/settings/case-filings', undefined, params)

    this.$events.updated.trigger(response.data)

    return response
  }

  async updateCaseAutomationSettings(params: any) {
    const response = await this.call<Organization>('post', '/settings/case-automations', undefined, params)

    this.$events.updated.trigger(response.data)

    return response
  }
}
