import type { EventHook } from '@vueuse/core'
import type { DateTime } from 'luxon'
import type { RequestEventHookMap, RequestEvents } from '../../factory'
import HttpFactory from '../../factory'
import type { OrganizationChartOfAccounts } from '../accounts'
import type { Case } from './'

export interface CaseLedgerItem {
  id: string
  type: 'LedgerPayment' | 'LedgerCharge'
  organization_chart_of_accounts_id: string
  amount?: string
  charge_date?: string
  description?: string
  origin_id?: string
  service_from?: string
  service_to?: string
  service_type?: string
  payment_date?: string
  payment_type?: string
  reversal_description?: string
  reversal_trans_id?: string
  reversal_type?: string
  gl: OrganizationChartOfAccounts
  created_at: string
  updated_at: string
}

export interface CaseLedger {
  accounts: OrganizationChartOfAccounts[]
  balance: string
  total_charges_amount: string
  total_payments_amount: string
  court_credit: string
  transactions: CaseLedgerItem[]
}

export interface AccountTransaction {
  account: string
  balance: string
}

export interface UpdateCaseLedgerItem
  extends Pick<
    CaseLedgerItem,
    'amount' | 'organization_chart_of_accounts_id' | 'description'
  > {
  charge_date?: string | DateTime
  payment_date?: string | DateTime
}

export interface CaseLedgerHookMap extends RequestEventHookMap {
  chargeUpdated: CaseLedgerItem
  paymentUpdated: CaseLedgerItem
}

export interface CaseLedgerEvents extends RequestEvents {
  chargeUpdated: EventHook<CaseLedgerHookMap['chargeUpdated']>
  paymentUpdated: EventHook<CaseLedgerHookMap['paymentUpdated']>
}

export class CaseLedgerModule extends HttpFactory<
  CaseLedgerHookMap,
  CaseLedgerEvents
> {
  protected $events = {
    chargeUpdated: createEventHook(),
    paymentUpdated: createEventHook(),
  }

  async list(caseId: Case['id']) {
    return await this.call<CaseLedger>('get', `/cases/${caseId}/ledger`)
  }

  async updateCharge(
    caseId: Case['id'],
    chargeId: CaseLedgerItem['id'],
    params: UpdateCaseLedgerItem,
  ) {
    const response = await this.call<CaseLedgerItem>(
      'put',
      `/cases/${caseId}/charge/${chargeId}`,
      params,
    )

    await this.$events.chargeUpdated.trigger(response.data)

    return response
  }

  async updatePayment(
    caseId: Case['id'],
    paymentId: CaseLedgerItem['id'],
    params: UpdateCaseLedgerItem,
  ) {
    const response = await this.call<CaseLedgerItem>(
      'put',
      `/cases/${caseId}/payment/${paymentId}`,
      params,
    )

    await this.$events.paymentUpdated.trigger(response.data)

    return response
  }
}
