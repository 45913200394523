import revive_payload_client_xb55dus82Q from "/buddy/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.46_encoding@0.1.13_eslint@8.57.0_ioredis@_pp4o25ouglwi7stoinaaykwgka/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_vG2kXHCi4u from "/buddy/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.46_encoding@0.1.13_eslint@8.57.0_ioredis@_pp4o25ouglwi7stoinaaykwgka/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_qJgi7ykTnh from "/buddy/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.46_encoding@0.1.13_eslint@8.57.0_ioredis@_pp4o25ouglwi7stoinaaykwgka/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_p6OlJel2Ft from "/buddy/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.46_encoding@0.1.13_eslint@8.57.0_ioredis@_pp4o25ouglwi7stoinaaykwgka/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_6j7xI79o08 from "/buddy/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.46_encoding@0.1.13_eslint@8.57.0_ioredis@_pp4o25ouglwi7stoinaaykwgka/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_WZq7qOjQ67 from "/buddy/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.46_encoding@0.1.13_eslint@8.57.0_ioredis@_pp4o25ouglwi7stoinaaykwgka/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_P3tAmll7dz from "/buddy/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.46_encoding@0.1.13_eslint@8.57.0_ioredis@_pp4o25ouglwi7stoinaaykwgka/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_R98nPKsE7L from "/buddy/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.4_rollup@4.21.1_typescript@5.5.4_vue@3.4.38_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/buddy/apps/rcm-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_A4D3sPIjiv from "/buddy/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.46_encoding@0.1.13_eslint@8.57.0_ioredis@_pp4o25ouglwi7stoinaaykwgka/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_Vkh1kERawa from "/buddy/node_modules/.pnpm/@sentry+nuxt@8.30.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+api@1._w4ygxskzrrkznxqemqqvntpyiu/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/buddy/apps/rcm-frontend/.nuxt/sentry-client-config.mjs";
import plugin_Y7QXPlHye1 from "/buddy/node_modules/.pnpm/@nuxt+image-edge@1.0.0-28099902.2b6ed1c_magicast@0.3.4_rollup@4.21.1/node_modules/@nuxt/image-edge/dist/runtime/plugin.mjs";
import api_GFfDXud5Cr from "/buddy/apps/rcm-frontend/plugins/api.ts";
import datepicker_client_q7vwJcr54B from "/buddy/apps/rcm-frontend/plugins/datepicker.client.ts";
import gtag_client_Zw8EQXNVTz from "/buddy/apps/rcm-frontend/plugins/gtag.client.ts";
import toast_ysMjUcU7eJ from "/buddy/apps/rcm-frontend/plugins/toast.ts";
export default [
  revive_payload_client_xb55dus82Q,
  unhead_vG2kXHCi4u,
  router_qJgi7ykTnh,
  payload_client_p6OlJel2Ft,
  navigation_repaint_client_6j7xI79o08,
  check_outdated_build_client_WZq7qOjQ67,
  chunk_reload_client_P3tAmll7dz,
  plugin_vue3_R98nPKsE7L,
  components_plugin_KR1HBZs4kY,
  prefetch_client_A4D3sPIjiv,
  sentry_client_Vkh1kERawa,
  sentry_client_config_o34nk2sJbg,
  plugin_Y7QXPlHye1,
  api_GFfDXud5Cr,
  datepicker_client_q7vwJcr54B,
  gtag_client_Zw8EQXNVTz,
  toast_ysMjUcU7eJ
]