import type { AxiosInstance } from 'axios'
import type { IntegrationPlatform } from '../'
import HttpFactory from '~/repository/factory'

export class IntegrationPlatformsModule extends HttpFactory {
  protected $events = {}

  constructor(ax: AxiosInstance) {
    super(ax)
  }

  async list() {
    return await this.call<IntegrationPlatform[]>('get', '/integration-platforms')
  }

}
