import type { EventHook } from '@vueuse/core'
import FileSaver from 'file-saver'
import HttpFactory from '../../factory'
import type { PaginatedParams, RequestEventHookMap, RequestEvents, SearchableParams, SortableParams } from '../../factory'
import type { Case } from '../cases'
import type { Lease } from './'

export interface LeaseTransaction {
  id: string
  class: string
  date: string
  case_id: string
  case_archive: boolean
  case_number: string
  account_number: string
  account_description: string
  description: string
  transaction_id: string
  amount: string
  service_from: string
  use_for_case_calculations: boolean
}

export interface CreateLeaseFee {
  amount: string
  note: string
  fee_date: string
  service_from: string
  account: string
}

export interface CreateLeasePayment {
  amount: string
  note: string
  payment_date: string
  service_from: string
  account: string
  ledger_charges_id: string
  reference_number: string
}

export interface TransactionHookMap extends RequestEventHookMap {
  feeCreated: LeaseTransaction
  paymentCreated: LeaseTransaction
}

export interface TransactionEvents extends RequestEvents {
  feeCreated: EventHook<TransactionHookMap['feeCreated']>
  paymentCreated: EventHook<TransactionHookMap['paymentCreated']>
}

export class LeaseTransactionsModule extends HttpFactory<TransactionHookMap, TransactionEvents> {
  protected $events = {
    feeCreated: createEventHook(),
    paymentCreated: createEventHook(),
  }

  async list(leaseId: Lease['id'], params: PaginatedParams & SearchableParams & SortableParams) {
    return await this.call<LeaseTransaction[]>('get', `/leases/${leaseId}/transactions`, params)
  }

  async listPayments(leaseId: Lease['id'], params: SearchableParams) {
    return await this.call<LeaseTransaction[]>('get', `/leases/${leaseId}/transactions/payments`, params)
  }

  async listCharges(leaseId: Lease['id'], params: SearchableParams, caseId?: Case['id']) {
    return await this.call<LeaseTransaction[]>('get', `/leases/${leaseId}/transactions/charges/${caseId}`, params)
  }

  async createFee(leaseId: Lease['id'], params: CreateLeaseFee) {
    const response = await this.call<LeaseTransaction>('post', `/leases/${leaseId}/transactions/fee`, params)

    await this.$events.feeCreated.trigger(response.data)

    return response
  }

  async createPayment(leaseId: Lease['id'], params: CreateLeasePayment) {
    const response = await this.call<LeaseTransaction>('post', `/leases/${leaseId}/transactions/payments`, params)

    await this.$events.paymentCreated.trigger(response.data)

    return response
  }

  async download(leaseId: Lease['id']) {
    const response = await this.call('post', `/leases/${leaseId}/transactions/download`, undefined, undefined, {
      responseType: 'blob',
    })

    FileSaver.saveAs(response as any as Blob, 'lease_ledgers.xlsx')
  }
}
