import { DateTime } from 'luxon'
import { defineStore } from 'pinia'
import type { AuthUser } from '~/repository/modules'

type RefreshTimeout = NodeJS.Timeout | null | number

function clearRefreshTokenTimeout(refreshTimeout: Ref<RefreshTimeout>) {
  const timeout = refreshTimeout.value
  if (timeout) {
    clearTimeout(timeout)
    refreshTimeout.value = null
  }
}

function setRefreshTokenTimeout(expirationTime: DateTime, ttl: number, refreshTimeout: Ref<RefreshTimeout>, callback: Function) {
  clearRefreshTokenTimeout(refreshTimeout)

  // reduce by 10 minutes
  const refreshAt = expirationTime.minus({ seconds: ttl * 0.1 })
  const millisecondsUntilRefresh = refreshAt.diffNow('milliseconds').milliseconds
  refreshTimeout.value = setTimeout(callback, millisecondsUntilRefresh)
}

export const useAuthStore = defineStore('auth', () => {
  const { $api } = useNuxtApp()

  const { generateTimestamp, fromISO } = useDate()
  const router = useRouter()
  const route = useRoute()

  const user = useCookie<AuthUser | undefined>('auth.user')
  const authCookie = useCookie<{ token?: string; expiration?: string, ttl?: number }>('auth.cookie')

  const loading = ref<boolean>(false)
  const refreshTokenTimeout = ref<RefreshTimeout>(null)

  const getSession = async () => {
    const { data } = await $api.auth.session()
    user.value = data
  }

  const getRefreshToken = async () => {
    const { data } = await $api.auth.refresh()
    authCookie.value = {
      token: data.token,
      expiration: generateTimestamp(data.expires_in).toISO(),
      ttl: data.expires_in,
    }

    const expirationTime = fromISO(authCookie.value.expiration!)
    const ttl = authCookie.value.ttl!
    
    setRefreshTokenTimeout(expirationTime, ttl, refreshTokenTimeout, requestRefreshTokenLock)

    await getSession()
  }

  const requestRefreshTokenLock = async () => {
    if (!navigator.locks) {
      await getRefreshToken()
      return
    }
    
    navigator.locks.request('cookie-refresh-lock', { ifAvailable: true }, async (lock) => {
      if (!lock) {
        return
      }
      
      /**
       * The lock has been acquired.
       */
      
      await getRefreshToken()
      
      /**
       * Now the lock will be released.
       */
    });
  }

  // actions
  const logout = async (savePath = true) => {
    clearRefreshTokenTimeout(refreshTokenTimeout)

    authCookie.value = {
      token: undefined,
      expiration: undefined,
      ttl: undefined,
    }
    user.value = undefined

    await nextTick(async () => {
      await router.push({
        path: '/auth/login',
        query: {
          next: savePath ? route.path : undefined,
        },
      })
    })
  }

  const login = async (email: string, password: string) => {
    loading.value = true
    user.value = undefined

    return $api.auth.login(email, password)
      .then(({ data }) => {
        user.value = data.user

        authCookie.value = {
          token: data.token,
          expiration: generateTimestamp(data.expires_in).toISO(),
          ttl: data.expires_in,
        }
      })
      .finally(() => loading.value = false)
  }

  const userCan = (permission: string): boolean => {
    return user.value?.permissions?.includes(permission) || false
  }

  // getters
  const isAdmin = computed(() => {
    return user.value?.roles.includes('Administrator')
  })

  const isSuperAdmin = computed(() => {
    return user.value?.roles.includes('Global Super Admin')
  })

  const isReadOnly = computed(() => {
    return user.value?.roles.includes('Read Only')
  })

  
  const token = computed(() => {
    return authCookie.value?.token
  })
  
  const expiration = computed(() => {
    return authCookie.value?.expiration
  })

  const loggedIn = computed(() => {
    return token.value !== undefined
  })

  watch(authCookie, async (auth, oldAuth) => {
    // firing double, so check if the token has actually changed
    if (auth?.token === oldAuth?.token) {
      return
    }

    const onLoginPage = route.path.includes('/auth/login')
    const onIndexPage = route.path === '/'
    
    // clear the timeout if the token is not set
    if (!auth?.expiration || !auth?.ttl) {
      clearRefreshTokenTimeout(refreshTokenTimeout)

      // if the user is not on the login page or index page, redirect to the index page
      if (!onLoginPage) {
        await logout(!onIndexPage)
      }

      return
    }
    
    // if the token is more than 90% expired, refresh it
    const expirationTime = fromISO(auth.expiration)
    const secondsUntilExpiration = expirationTime.diffNow('seconds').seconds
    const percentExpired = (1 - (secondsUntilExpiration / auth.ttl)) * 100
    
    if (percentExpired >= 90) {
      requestRefreshTokenLock()
      return
    }

    if (onLoginPage) {
      await router.push(route.query.next as string || '/dashboard')
    }

    setRefreshTokenTimeout(expirationTime, auth.ttl, refreshTokenTimeout, requestRefreshTokenLock)
  }, {
    immediate: true,
  })

  return {
    user,
    loading,
    loggedIn,
    isAdmin,
    isSuperAdmin,
    isReadOnly,
    expiration,
    token,

    logout,
    login,
    getSession,
    userCan,
  }
})
