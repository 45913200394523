import type { PaginatedParams, SearchableParams } from '../factory'
import HttpFactory from '../factory'
import type { Building } from './buildings'
import type { Lease } from './leases'
import {Court} from "~/repository/modules/courts";

export interface UnitSettings {
  lead_certificate_settings: {
    lead_exempt: boolean
    lead_free: boolean
    lead_certificate_number: string | null
  }
  registration_settings: {
    required_to_be_registered: boolean
    registered: boolean
    registration_number: string | null
    license_expiration_date: string | null
  }
}

export interface Unit {
  id: string
  origin_id: string | null
  origin_unit_number : string | null
  address_line_1: string
  address_line_2: string | null
  city: string
  state: string
  postal_code: string
  full_address: string | null
  has_lease: boolean
  lease: Lease
  cases_count: number
  court_name: string | null
  court_id: string | null
  settings: UnitSettings
  status: boolean
  building: Building
  court: Court
  created_at: string
  sold: boolean
}

export class UnitsModule extends HttpFactory {
  protected $events = {}

  async list(params: PaginatedParams & SearchableParams) {
    return this.call<Unit[]>('get', '/units', params)
  }

  async get(id: Unit['id']) {
    return this.call<Unit>('get', `/units/${id}`)
  }

  async update(id: Unit['id'], data: Partial<Unit>) {
    return this.call<Unit>('put', `/units/${id}`, data)
  }

  async toggleUnitSold(id: Unit['id'], sold: boolean) {
    return this.call<Unit>('put', `units/${id}/toggle-unit-sold`, undefined, { sold })
  }
}
