import type { EventHook } from '@vueuse/core'
import type { DateTime } from 'luxon'
import type { RequestEventHookMap, RequestEvents } from '../../factory'
import HttpFactory from '../../factory'
import type { WorkflowStep } from '../workflow'

export interface DocketWizardItem {
  case_prefix: string
  days: number[]
  times: {
    [key: number]: string[]
  }
}

export interface DocketWizardData {
  filing_limit: number
  docket_wizard_data: {
    [key: string]: DocketWizardItem
  }
}

export type SaveDocketWizardData<T extends Record<string, any> = {}> = Array<{
  case_id: string
  case_number: string
  court_date: string | DateTime
  court_time: string
  prefix: string
} & T>

export interface CaseDocketHookMap extends RequestEventHookMap {
  saved: undefined
}

export interface CaseDocketEvents extends RequestEvents {
  saved: EventHook<CaseDocketHookMap['saved']>
}

export class CaseDocketWizardModule extends HttpFactory<CaseDocketHookMap, CaseDocketEvents> {
  protected $events = {
    saved: createEventHook(),
  }

  async data(workflowStepId: WorkflowStep['id']) {
    return await this.call<DocketWizardData>('get', `workflow/steps/${workflowStepId}/docket-wizard-data`)
  }

  async save(params: SaveDocketWizardData) {
    const response = await this.call('post', '/cases/store-docket-wizard', {
      cases: params,
    })

    await this.$events.saved.trigger(undefined)

    return response
  }
}
