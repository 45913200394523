import HttpFactory from '../factory'
import type { Permission } from './permissions'
import type { Role } from './roles'
import type { AssignedCommunity } from './users'

export interface AuthUser {
  id: string
  first_name: string
  last_name: string
  email: string
  organization_id: string
  roles: Role[]
  permissions: Permission[]
  assigned_communities: AssignedCommunity[]
}

export interface LoginResponse {
  expires_in: number
  token: string
  token_type: 'bearer'
  user: AuthUser
}

export interface LoginParams {
  email: string
  password: string
}

export interface ForgotPasswordParams {
  email: string
}

export interface ResetPasswordParams {
  token: string
  email: string
  password: string
  password_confirmation: string
  type: string
}

export class AuthModule extends HttpFactory {
  protected $events = {}

  async login(email: string, password: string) {
    return await this.call<LoginResponse>('post', '/auth/login', {}, { email, password })
  }

  async forgotPassword(email: string) {
    return await this.call('post', '/auth/forgot-password', {}, { email })
  }

  async resetPassword(token: string, email: string, password: string, password_confirmation: string, type: string = 'reset') {
    return await this.call('post', '/auth/reset-password', {}, { token, email, password, password_confirmation, type })
  }

  async refresh() {
    return await this.call<LoginResponse>('post', '/auth/refresh')
  }

  async session() {
    return await this.call<AuthUser>('get', '/session')
  }
}
