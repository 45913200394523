import type { EventHook } from '@vueuse/core'
import type { PaginatedParams, RequestEventHookMap, RequestEvents } from '../../factory'
import HttpFactory from '../../factory'
import type { Resident } from '../residents'
import type { Lease } from './'

export interface LeaseCase {
  id: string
  current_step_id?: string
  current_step_name?: string
  dc_cv_115_rent_due_for?: string
  ftpr_rent_due_for?: string
  unit_id: string
  description: string
  address_line_1: string
  address_line_2: string
  city: string
  state: string
  postal_code: string
  origin_id: string
  residents: Resident[],
  flags: Array<string>,
  wor_created_at?: string,
}

export interface CreateLeaseCase {
  type_of_case: string
}

export interface LeaseHookMap extends RequestEventHookMap {
  created: LeaseCase
}

export interface LeaseEvents extends RequestEvents {
  created: EventHook<LeaseHookMap['created']>
}

export class LeaseCasesModule extends HttpFactory<LeaseHookMap, LeaseEvents> {
  protected $events = {
    created: createEventHook(),
  }

  async list(leaseId: Lease['id'], params: PaginatedParams) {
    return await this.call<LeaseCase[]>('get', `/leases/${leaseId}/cases`, params)
  }

  async create(leaseId: Lease['id'], params: CreateLeaseCase) {
    const response = await this.call<LeaseCase>('post', `/leases/${leaseId}/cases`, params)

    await this.$events.created.trigger(response.data)

    return response
  }
}
