import type { EventHook } from '@vueuse/core'
import type { PaginatedParams, RequestEventHookMap, RequestEvents } from '../../factory'
import HttpFactory from '../../factory'
import type { WorkflowStep } from '../workflow'
import type { User } from '../users'
import type { Case } from './'

export interface CaseNote {
  id: string
  note: string
  user?: User
  workflow_step?: WorkflowStep
  created_at: string
  case_id: string
}

export type CreateCaseNote = Pick<CaseNote, 'note'>

export interface NoteHookMap extends RequestEventHookMap {
  created: CaseNote
}

export interface NoteEvents extends RequestEvents {
  created: EventHook<NoteHookMap['created']>
}

export class CaseNotesModule extends HttpFactory<NoteHookMap, NoteEvents> {
  protected $events = {
    created: createEventHook(),
  }

  async list(caseId: Case['id'], params: PaginatedParams) {
    return await this.call<CaseNote[]>('get', `/cases/${caseId}/notes`, params)
  }

  async create(caseId: Case['id'], params: CreateCaseNote) {
    const response = await this.call<CaseNote>('post', `/cases/${caseId}/notes`, params)

    const responseDataWithCaseId = {
      ...response.data,
      case_id: caseId,
    }
    await this.$events.created.trigger(responseDataWithCaseId)

    return response
  }
}
