import type { EventHook } from '@vueuse/core'
import HttpFactory from '../../factory'
import type { RequestEventHookMap, RequestEvents } from '../../factory'
import type { AllowedCategory } from '../allowedCategories'
import type { CaseSummary } from '.'

export interface IAdjudicationCase extends Pick<CaseSummary,
  'id'
  | 'case_number'
  | 'disposition'
  | 'resident_appeared'
  | 'court_credit'
  | 'computed_ftpr_amended_value'
  | 'computed_ftpr_value'
  | 'disposition_value'
  | 'stipulations'
  > {
  disposition_manually_adjusted: boolean // rename to the below
}
export interface IAdjudication {
  move_to_appropriate_disposition: boolean
  judge_id: string
  judge_feedback?: string
  judges_allowed_category: (AllowedCategory & {
    note: string
  })[]
  cases: IAdjudicationCase[]
}

export interface CaseAdjudicationHookMap extends RequestEventHookMap {
  created: undefined
}

export interface CaseAdjudicationEvents extends RequestEvents {
  created: EventHook<CaseAdjudicationHookMap['created']>
}

export class CaseAdjudicationModule extends HttpFactory<CaseAdjudicationHookMap, CaseAdjudicationEvents> {
  protected $events = {
    created: createEventHook(),
  }

  async create(data: IAdjudication) {
    const response = await this.call<{ message: string }>('post', '/cases/adjudication', undefined, data)

    await this.$events.created.trigger(undefined)

    return response
  }
}
