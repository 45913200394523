import type { PaginatedParams, SearchableParams } from '../factory'
import HttpFactory from '../factory'
import type { Building } from './buildings'
import type { Unit } from './units'

export interface CommunitySettings {
  phone: string | null
  website: string | null
  logo: string | null
}

export interface Community {
  id: string
  name: string
  address_line_1: string
  address_line_2: string | null
  city: string
  state: string
  postal_code: string
  settings: CommunitySettings
  building_count: number
  unit_count: number
  updated_at: string
  created_at: string
}

export class CommunitiesModule extends HttpFactory {
  protected $events = {}

  async list(params: PaginatedParams & SearchableParams) {
    return this.call<Community[]>('get', '/communities', params)
  }

  async get(id: Community['id']) {
    return this.call<Community>('get', `/communities/${id}`)
  }

  async listBuildings(id: Community['id'], params: PaginatedParams & SearchableParams) {
    return this.call<Building[]>('get', `/communities/${id}/buildings`, params)
  }

  async listUnits(id: Community['id'], params: PaginatedParams & SearchableParams) {
    return this.call<Unit[]>('get', `/communities/${id}/units`, params)
  }
}
