import type { AxiosInstance } from 'axios'
import type { EventHook } from '@vueuse/core'
import type { RequestEventHookMap, RequestEvents } from '../factory'
import HttpFactory from '../factory'
import {Firm} from "~/repository/modules/firms";
export interface Attorney {
  id: string
  origin_id: string
  origin_platform: string
  firm: Firm
  bar_number: string
  first_name: string
  middle_name: string
  last_name: string
  email: string
  updated_at: string
}

export interface AttorneyHookMap extends RequestEventHookMap {
  updated: Attorney
}

export interface AttorneyEvents extends RequestEvents {
  updated: EventHook<Attorney>
}

export class AttorneysModule extends HttpFactory<AttorneyHookMap, AttorneyEvents> {
  protected $events = {
    updated: createEventHook(),
  }

  constructor(ax: AxiosInstance) {
    super(ax)
  }

  async list() {
    return await this.call<Attorney[]>('get', '/attorneys')
  }

  async getById(id: Attorney['id']) {
    return await this.call<Attorney>('get', `/attorneys/${id}`)
  }
}
