import type { EventHook } from '@vueuse/core'
import type { RequestEventHookMap, RequestEvents } from '../factory'
import HttpFactory from '../factory'
import type { IPermission, Permission } from './permissions'
import type { Resource } from './resources'

export type Role = string

export interface IRole {
  id?: string
  name: string
  description: string
  organization_id: string
  permissions: Permission[]
  parent_id?: string
  inherits_from_parent?: boolean
  is_default?: boolean
  resource?: Resource
}

export interface IRoleOption extends Pick<IRole,
  | 'description'
  | 'inherits_from_parent'
  | 'parent_id'
  | 'resource'
> {
  value: Role
  label: string
  permissions: IPermission[]
}

export type AvailableRoles = IRoleOption[]

export interface RolesHookMap extends RequestEventHookMap {
  created: IRole
  cloned: IRole
  updated: IRole
  deleted: IRole
}

export interface RolesEvents extends RequestEvents {
  created: EventHook<RolesHookMap['created']>
  cloned: EventHook<RolesHookMap['cloned']>
  updated: EventHook<RolesHookMap['updated']>
  deleted: EventHook<RolesHookMap['deleted']>
}

export class RolesModule extends HttpFactory<RolesHookMap, RolesEvents> {
  protected $events = {
    created: createEventHook(),
    cloned: createEventHook(),
    updated: createEventHook(),
    deleted: createEventHook(),
  }

  async availableRoles(display: boolean = false) {
    return await this.call<AvailableRoles>('get', '/authorization/available-roles', {display})
  }

  async createRole(role: IRole) {
    const response = await this.call<IRole>('post', '/authorization/roles/create', undefined, role)
    await this.$events.created.trigger(response)
    return response
  }

  async cloneRole(roleId: any, supplemental: any = {}) {
    const response = await this.call<any>('post', `/authorization/roles/${roleId}/clone`, undefined, supplemental)
    await this.$events.cloned.trigger(response)
    return response
  }

  async getRole(roleId: any) {
    return await this.call<any>('get', `/authorization/roles/${roleId}`)
  }

  async updateRole(role: any) {
    const response = await this.call<any>('put', `/authorization/roles/${role.id}`, undefined, role)
    await this.$events.updated.trigger(response)
    return response
  }

  async deleteRole(roleId: any) {
    const response = await this.call<any>('delete', `/authorization/roles/${roleId}`)
    await this.$events.deleted.trigger(response)
    return response
  }
}
