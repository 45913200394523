import HttpFactory from '../../factory'
import type { Lease } from './'

export interface LeaseAccount {
  id: string
  gl_account: string
  mode: string
  account: string
  description: string
  payments: string
  charges: string
  use_for_case_calculations: boolean
}

export interface LeaseAccountResponse {
  lease_accounts: LeaseAccount[]
  computed: {
    charges_total?: number
    payments_total?: number
    ledger_total?: number
    case_value?: number
  }
}

export class LeaseAccountsModule extends HttpFactory {
  protected $events = {}

  async list(leaseId: Lease['id']) {
    return await this.call<LeaseAccountResponse>('get', `/leases/${leaseId}/accounts`)
  }
}
