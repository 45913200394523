import type {AxiosError, AxiosInstance} from 'axios'
import type {EventHook} from '@vueuse/core'
import FileSaver from 'file-saver'
import type {
  FilteredParams,
  IMessageResponse,
  PaginatedParams,
  RequestEventHookMap,
  RequestEvents,
  SearchableParams,
  SortableParams,
  WhereClauseParams,
} from '../../factory'
import HttpFactory from '../../factory'
import type {Lease, LeaseResident} from '../leases'
import type {WorkflowStep, WorkflowStepType} from '../workflow'
import type {Unit} from '../units'
import type {CaseNote} from './caseNotes'
import {CaseNotesModule} from './caseNotes'
import {CaseActivityModule} from './caseActivities'
import {CaseLedgerModule} from './caseLedger'
import {Case115TrackingModule} from './case115Tracking'
import type {CaseWatch} from './caseWatches'
import {CaseWatchesModule} from './caseWatches'
import {CaseDocketWizardModule} from './caseDocketWizard'
import {CaseEvictionWizardModule} from './caseEvictionWizard'
import type {CourtDate} from './caseCourtDate'
import {CaseCourtDateModule} from './caseCourtDate'
import {CaseAdjudicationModule} from './caseAdjudication'
import type {CaseDisposition} from './caseDispositions'
import type {CaseStipulation} from './caseStipulations'
import {
  CaseChangeEvictionDateModule,
  CaseChangeTrialDateModule,
  CaseRescheduleCourtDatesModule,
} from '~/repository/modules'

const { formatDate } = useCommonHelpers()

export interface CaseStepProcessingMetadata {
  to: WorkflowStepType
}

export interface CaseStepProcessingData {
  case_id: string
  metadata: CaseStepProcessingMetadata
}

export interface ICaseErrors {
  case_id: string
  errors: string[]
}

export interface ICaseErrorsById {
  [caseId: string]: string[]
}

export interface Case {
  id: string
  has_errors: boolean
  workflow_step_id: WorkflowStep['id']
  case_number: string | null
  dccv115_rent_due_for?: string
  dccv115_value: string
  dccv115_created_at?: string
  dccv115_mailed_at?: string
  dccv115_file_path?: string
  dccv082_file_path?: string
  dccv081_file_path?: string
  case_value?: string
  ftpr_value?: string
  ftpr_amended_value?: string
  ftpr_created_at?: string
  ftpr_rent_due_for: string | null
  wor_value?: string
  wor_amended_value?: string
  wor_created_at: string | null
  disposition_created_at?: string
  eviction_notice_created_at?: string
  eviction_date?: string | null
  first_fee_date?: string
  case_value_rent_only: boolean
  lease?: Lease
  unit?: Unit
  created_at: string
  on_hold: boolean
  processing_requested: boolean
  processing_metadata?: CaseStepProcessingMetadata
  escrow: boolean
  request_future_rent: boolean
  request_foreclosure_of_residents_right_of_redemption: boolean
  resident_appeared: boolean
  court_credit: number
  docket_id: number
  disposition: CaseDisposition
  stipulations: CaseStipulation[]
  disposition_value: string | number
}

export interface CaseSummary extends Omit<
  Case, 'dccv115_value' | 'dccv115_created_at' | 'dccv115_mailed_at' | 'dccv115_file_path' | 'case_value' | 'ftpr_value' | 'ftpr_amended_value'
  | 'wor_value' | 'wor_amended_value' | 'disposition_created_at' | 'eviction_notice_created_at' | 'first_fee_date' | 'case_value_rent_only'
> {
  batch_id: string | null
  batch_created_at?: string
  case_number: string | null
  resident_military: boolean
  request_future_rent: boolean
  request_foreclosure_of_residents_right_of_redemption: boolean
  wor_batch_id?: string
  eviction_date?: string
  disposition_date?: string
  dc_cv_115_created_date: string | null
  dc_cv_115_mailed_at: string | null
  dc_cv_115_late_fee: number
  dc_cv_115_late_fee_due_for: string | null
  dc_cv_115_rent_due_for: string | null
  archive: boolean
  cancel: boolean
  has_errors: boolean
  flags: Array<string>
  unit_id: string
  unit_address_line_1: string
  unit_address_line_2: string | null
  unit_city: string
  unit_state: string
  unit_postal_code: string
  lease_id: string
  lease_origin_id?: string
  lease_rent: number
  lease_due_on: number
  residents: LeaseResident[]
  note?: CaseNote
  notes_count: number | null
  date?: CourtDate
  computed_value: number | null
  computed_fees: number | null
  computed_dc_cv_115_value: number | null
  computed_dc_cv_115_mailed_at: string | null
  computed_ftpr_value: string | number | null
  computed_ftpr_amended_value: string | number | null
  computed_wor_value: number | null
  computed_wor_amended_value: number | null
  computed_amended_payment: boolean
  computed_zero_balance: boolean
  file_frr: boolean
  frr_eligible: boolean
  court_name: string | null
  court_date_id: string | null
  court_date: string | null
  court_time: string | null
  court_availability_days: null | string[]
  court_availability_times__by_days: Record<string, Array<string>> | null
  created_at: string
  updated_at: string
  watch: CaseWatch
  current_step_name: string
  previous_step_name: string | null
  processing_metadata?: CaseStepProcessingMetadata
  escrow: boolean
  stipulations: CaseStipulation[]
  disabled?: boolean
}

export interface CaseFilterData {
  units: Array<string>
  communities: Array<string>
  lease_due_on: Array<number>
  batch_id: Array<string>
  court_name: Array<string>
  court_date: Array<string>
  court_time: Array<string>
  eviction_date: Array<Date>
  dc_cv_115_rent_due_for: Array<string>
  ftpr_rent_due_for: Array<string>
}

export interface MoveCases {
  to: WorkflowStepType
  case_ids: Array<Case['id']>
}

export interface ApprovedForProcessingCases {
  case_ids: Array<Case['id']>
  metadata: Record<string, any>
}

export interface ChallengeCaseTransitionParams {
  note: string
  case_ids: Array<Case['id']>
}

export interface PlaceHoldCases {
  case_ids: Array<Case['id']>
  hold: boolean
  note?: string
}

export interface Document {
  id: string
  name: string
  link: string
  type: string
  location: string
  documentable_type?: string
  documentable_id?: string
  file_size?: number
  batch_size?: number
  file_extension?: string
  group_id?: string
  zip_file_location?: string
  zip_file_size?: number
  zip_group_items?: number
  created_at: string
  updated_at: string
}

export interface CaseHookMap extends RequestEventHookMap {
  ftprCreated: undefined
  triaged: Case
  moved: undefined
  moveStarted: undefined
  moveFailed: ICaseErrorsById
  scraImported: undefined
  evictionNoticeGenerated: undefined
  updated: Case
  batchCreated: Array<Document>
  transitionChallenged: undefined
  touched: undefined
}

export interface CaseEvents extends RequestEvents {
  ftprCreated: EventHook<CaseHookMap['ftprCreated']>
  triaged: EventHook<CaseHookMap['triaged']>
  moved: EventHook<CaseHookMap['moved']>
  evictionNoticeGenerated: EventHook<CaseHookMap['evictionNoticeGenerated']>
  moveStarted: EventHook<CaseHookMap['moveStarted']>
  moveFailed: EventHook<CaseHookMap['moveFailed']>
  scraImported: EventHook<CaseHookMap['scraImported']>
  updated: EventHook<CaseHookMap['updated']>
  batchCreated: EventHook<CaseHookMap['batchCreated']>
  transitionChallenged: EventHook<CaseHookMap['transitionChallenged']>
  touched: EventHook<CaseHookMap['touched']>
}

export class CasesModule extends HttpFactory<CaseHookMap, CaseEvents> {
  public $events = {
    ftprCreated: createEventHook(),
    triaged: createEventHook(),
    moved: createEventHook(),
    evictionNoticeGenerated: createEventHook(),
    moveStarted: createEventHook(),
    moveFailed: createEventHook(),
    scraImported: createEventHook(),
    updated: createEventHook(),
    batchCreated: createEventHook(),
    transitionChallenged: createEventHook(),
    touched: createEventHook(),
  }

  adjudication: CaseAdjudicationModule
  notes: CaseNotesModule
  activity: CaseActivityModule
  ledger: CaseLedgerModule
  tracking: Case115TrackingModule
  watches: CaseWatchesModule
  docketWizard: CaseDocketWizardModule
  evictionWizard: CaseEvictionWizardModule
  rescheduleCourtDate: CaseRescheduleCourtDatesModule
  changeTrialDate: CaseChangeTrialDateModule
  changeEvictionDate: CaseChangeEvictionDateModule
  courtDate: CaseCourtDateModule

  constructor(ax: AxiosInstance) {
    super(ax)
    this.adjudication = new CaseAdjudicationModule(ax)
    this.notes = new CaseNotesModule(ax)
    this.activity = new CaseActivityModule(ax)
    this.ledger = new CaseLedgerModule(ax)
    this.tracking = new Case115TrackingModule(ax)
    this.watches = new CaseWatchesModule(ax)
    this.docketWizard = new CaseDocketWizardModule(ax)
    this.evictionWizard = new CaseEvictionWizardModule(ax)
    this.rescheduleCourtDate = new CaseRescheduleCourtDatesModule(ax)
    this.changeTrialDate = new CaseChangeTrialDateModule(ax)
    this.changeEvictionDate = new CaseChangeEvictionDateModule(ax)
    this.courtDate = new CaseCourtDateModule(ax)
  }

  async list(workflowStepId: WorkflowStep['id'], params: SearchableParams & PaginatedParams & SortableParams & FilteredParams & WhereClauseParams) {
    return await this.call<CaseSummary[]>('get', `/workflow/steps/${workflowStepId}/cases`, params)
  }

  async listAll(params: SearchableParams & PaginatedParams & SortableParams & FilteredParams & Record<string, any>) {
    return await this.call<CaseSummary[]>('get', '/case-summaries', params)
  }

  async listByBatchId(params: { batch_id: string }) {
    return await this.call<Case[]>('get', '/cases/get-cases-by-batch-number', params)
  }

  async get(id: Case['id']) {
    return await this.call<Case>('get', `/cases/${id}`)
  }

  async update(id: Case['id'], data: Partial<Omit<Case, 'id'>>) {
    const response = await this.call<Case>('post', `/cases/${id}`, undefined, data)

    await this.$events.updated.trigger(response.data)

    return response
  }

  async generateFTPR(id: Case['id']) {
    const response = await this.call('post', `/cases/${id}/documents/ftpr`)

    await this.$events.ftprCreated.trigger(undefined)

    return response
  }

  async filters(workflowStepId: WorkflowStep['id']) {
    return await this.call<CaseFilterData>('get', `/workflow/steps/${workflowStepId}/cases/filters`)
  }

  async processingFilters(onHold: boolean, stepId: string | null = null) {
    return await this.call<CaseFilterData>('get', `/cases-processing/${+onHold}/filters`, {
      casesForProcessing: 1,
      stepId,
    })
  }

  async flag(id: Case['id'], flag: boolean, note: string) {
    const response = await this.call<Case>('post', `/cases/${id}/flag`, {
      flag: +flag,
      note,
    })

    await this.$events.triaged.trigger(response.data)
    await this.$events.moved.trigger(undefined)

    return response
  }

  async downloadEvictionNotices(id: Case['id'] | undefined) {
    const response = await this.downloadFile<Blob>('post', `/cases/${id}/download-eviction-notice`, undefined, undefined)

    FileSaver.saveAs(response as any as Blob, 'eviction_notices.zip')

    return response
  }

  getCaseMoveErrors(data: any) {
    const errors: ICaseErrors[] = data?.errors ?? []
    return errors.reduce((acc: ICaseErrorsById, record: any) => {
      acc[record.case_id] = record.errors
      return acc
    }, {})
  }

  async move(params: MoveCases) {
    try {
      await this.$events.moveStarted.trigger()

      const response = await this.call('post', '/cases/move', undefined, params)

      const errorsByCaseId = this.getCaseMoveErrors(response.data)

      if (Object.keys(errorsByCaseId).length > 0)
        this.$events.moveFailed.trigger(errorsByCaseId)

      await this.$events.moved.trigger(undefined)

      return response
    }
    catch (e: any) {
      const err: AxiosError = e
      const errorsByCaseId = this.getCaseMoveErrors((err.response?.data as any)?.data)
      await this.$events.moveFailed.trigger(errorsByCaseId)
      throw e
    }
  }

  async refreshCaseLedgers(params: { case_ids: Array<Case['id']> }) {
    const response = await this.call<IMessageResponse>('post', '/cases/refresh-case-ledgers', undefined, {
      case_ids: params.case_ids,
    })

    await this.$events.updated.trigger(undefined)

    return response
  }

  async approvedForProcessing(params: ApprovedForProcessingCases) {
    return await this.call('post', '/cases/approved-for-processing', params)
  }

  async processTransition(params: Array<CaseStepProcessingData>) {
    return await this.call('post', '/cases/process-transition', undefined, { data: params })
  }

  async challengeTransition(params: ChallengeCaseTransitionParams) {
    const response = await this.call('post', '/cases/challenge-transition', undefined, params)

    await this.$events.transitionChallenged.trigger(undefined)

    return response
  }

  async setHoldStatus(params: PlaceHoldCases) {
    const response = await this.call('post', '/cases/set-hold-status', undefined, params)

    await this.$events.touched.trigger(undefined)

    return response
  }

  async setHoldRequestedStatus(params: PlaceHoldCases) {
    return await this.call('post', '/cases/request-hold', undefined, params)
  }

  async challengeHold(params: PlaceHoldCases) {
    const response = await this.call('post', '/cases/challenge-hold', undefined, params)

    await this.$events.transitionChallenged.trigger(undefined)

    return response
  }

  async file115(params: MoveCases) {
    try {
      const response = await this.call('post', '/cases/file-115', params)

      await this.$events.moved.trigger(undefined)

      return response
    }
    catch (e) {
      await this.$events.moveFailed.trigger(undefined)

      throw e
    }
  }

  async restore(params: Pick<MoveCases, 'case_ids'>) {
    const response = await this.call('post', '/cases/restore', params)

    await this.$events.moved.trigger(undefined)

    return response
  }

  async generateNotices(params: Pick<MoveCases, 'case_ids'>) {
    const response = await this.call('post', '/cases/generate-notices', params)

    await this.$events.moved.trigger(undefined)

    await this.$events.evictionNoticeGenerated.trigger(undefined)

    return response
  }

  async export(filename: string, params: { stepId: WorkflowStep['id']; stepName: WorkflowStep['name']; caseIds: Array<Case['id']> }) {
    const response = await this.downloadFile<Blob>('post', '/cases/export', undefined, {
      step_id: params.stepId,
      step_name: params.stepName,
      case_ids: params.caseIds,
    })
    FileSaver.saveAs(response, `${filename}.xlsx`)
  }

  async exportToPrintQueue(params: {
    stepId: WorkflowStep['id']
    stepName: WorkflowStep['name']
    caseIds: Array<Case['id']>
    format: string
  }) {
    const response = await this.call('post', '/cases/export-print-queue', undefined, {
      step_id: params.stepId,
      step_name: params.stepName,
      case_ids: params.caseIds,
      format: params.format,
    })

    await this.$events.moved.trigger(undefined)

    return response
  }

  async exportToWorSubmitted(params: {
    stepId: WorkflowStep['id']
    stepName: WorkflowStep['name']
    caseIds: Array<Case['id']>
    format: string
  }) {
    const response = await this.call('post', '/cases/export-wor-submitted', undefined, {
      step_id: params.stepId,
      step_name: params.stepName,
      case_ids: params.caseIds,
      format: params.format,
    })

    await this.$events.moved.trigger(undefined)

    return response
  }

  async exportTrialQueueTenant(params: { stepId: WorkflowStep['id']; stepName: WorkflowStep['name']; caseIds: Array<Case['id']> }) {
    return await this.call('post', '/cases/export-trial-queue-tenant', undefined, {
      step_id: params.stepId,
      step_name: params.stepName,
      case_ids: params.caseIds,
    })
  }

  async exportScra(data: Pick<MoveCases, 'case_ids'>) {
    const response = await this.downloadFile<Blob>('post', '/cases/scra-export', undefined, data)

    const currentDate = formatDate((new Date()).toString());
    const formattedDate = currentDate.replace(/\//g, "_");

    FileSaver.saveAs(response, 'scra_'+formattedDate+'.txt')
  }

  async importScra(params: { file: File }) {
    const formData = new FormData()
    formData.append('file', params.file)

    const response = await this.call('post', '/cases/scra-import', undefined, formData)

    await this.$events.scraImported.trigger(undefined)

    return response
  }
}

export * from './caseNotes'
export * from './caseActivities'
export * from './caseLedger'
export * from './case115Tracking'
export * from './caseDocketWizard'
export * from './caseEvictionWizard'
export * from './caseRescheduleCourtDate'
export * from './caseChangeTrialDate'
export * from './caseChangeEvictionDate'
export * from './caseCourtDate'
