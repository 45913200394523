import type { AxiosInstance } from 'axios'
import type { EventHook } from '@vueuse/core'
import type { PaginatedParams, RequestEventHookMap, RequestEvents, SearchableParams, SortableParams } from '../../factory'
import HttpFactory from '../../factory'
import type { LeaseResident } from './leaseResidents'
import { LeaseResidentsModule } from './leaseResidents'
import { LeaseDocumentsModule } from './leaseDocuments'
import { LeaseCasesModule } from './leaseCases'
import { LeaseTransactionsModule } from './leaseTransactions'
import { LeaseAccountsModule } from './leaseAccounts'
import { LeaseUnitModule } from './leaseUnit'
import { LeaseActivityModule } from './leaseActivities'

export interface Lease {
  id: string
  unit_id?: string
  start_date?: Date
  end_date?: Date
  move_in_date?: Date
  move_out_date?: Date
  portfolio?: string
  group?: string
  rent: number
  country?: string
  description?: string
  address_line_1: string
  address_line_2?: string
  city: string
  state: string
  postal_code: string
  residents: LeaseResident[]
  case_count: number
  created_by?: { first_name: string; last_name: string }
  origin_id: string
  rent_frequency?: { due_on: string; frequency: string }
  government_subsidized?: boolean
  frr: boolean
  flags: Array<string>
  escrow: boolean
  bankruptcy: boolean
  escrow_case_count: number
  eviction_prevention: boolean
  payment_plan: boolean
  rental_assistance: boolean
  employee_lease: boolean
}

export interface EscrowLease {
  case_id?: string
  escrow: boolean
}

export interface BankruptcyLease {
  lease_id: string | undefined
  bankruptcy: boolean
}

export interface EvictionPreventionLease {
  lease_id: string | undefined
  eviction_prevention: boolean
}

export interface PaymentPlanLease {
  lease_id: string | undefined
  payment_plan: boolean
}

export interface RentalAssistanceLease {
  lease_id: string | undefined
  rental_assistance: boolean
}

export interface EmployeeLease extends Pick<Lease, 'employee_lease'> {}

export interface LeaseHookMap extends RequestEventHookMap {
  touched: undefined
}

export interface LeaseEvents extends RequestEvents {
  touched: EventHook<LeaseHookMap['touched']>
}

export class LeasesModule extends HttpFactory<LeaseHookMap, LeaseEvents> {
  protected $events = {
    touched: createEventHook(),
  }

  residents: LeaseResidentsModule
  documents: LeaseDocumentsModule
  cases: LeaseCasesModule
  transactions: LeaseTransactionsModule
  accounts: LeaseAccountsModule
  unit: LeaseUnitModule
  activity: LeaseActivityModule

  constructor(ax: AxiosInstance) {
    super(ax)
    this.residents = new LeaseResidentsModule(ax)
    this.documents = new LeaseDocumentsModule(ax)
    this.cases = new LeaseCasesModule(ax)
    this.transactions = new LeaseTransactionsModule(ax)
    this.accounts = new LeaseAccountsModule(ax)
    this.unit = new LeaseUnitModule(ax)
    this.activity = new LeaseActivityModule(ax)
  }

  async list(params: SearchableParams & PaginatedParams & SortableParams) {
    return await this.call<Lease[]>('get', '/leases', params)
  }

  async get(id: Lease['id']) {
    return await this.call<Lease>('get', `/leases/${id}`)
  }

  async setEscrowStatus(leasId: Lease['id'], params: EscrowLease) {
    const response = await this.call('post', `/leases/${leasId}/set-escrow-status`, undefined, params)
    await this.$events.touched.trigger(undefined)
    return response
  }

  async setBankruptcyStatus(params: BankruptcyLease) {
    const response = await this.call('post', '/leases/set-bankruptcy-status', undefined, params)
    await this.$events.touched.trigger(undefined)
    return response
  }

  async setEvictionPreventionStatus(params: EvictionPreventionLease) {
    const response = await this.call('post', '/leases/set-eviction-prevention-status', undefined, params)
    await this.$events.touched.trigger(undefined)
    return response
  }

  async setPaymentPlanStatus(params: PaymentPlanLease) {
    const response = await this.call('post', '/leases/set-payment-plan-status', undefined, params)
    await this.$events.touched.trigger(undefined)
    return response
  }

  async setRentalAssistanceStatus(params: RentalAssistanceLease) {
    const response = await this.call('post', '/leases/set-rental-assistance-status', undefined, params)
    await this.$events.touched.trigger(undefined)
    return response
  }

  async setEmployeeLease(leaseId: Lease['id'], params: EmployeeLease) {
    const response = await this.call('post', `/leases/${leaseId}/set-employee-lease`, undefined, params)
    await this.$events.touched.trigger(undefined)
    return response
  }
}

export * from './leaseAccounts'
export * from './leaseCases'
export * from './leaseDocuments'
export * from './leaseResidents'
export * from './leaseTransactions'
export * from './leaseUnit'
