import type { PaginatedParams } from '../../factory'
import HttpFactory from '../../factory'
import type { Case } from './'
import type { Activity } from '~/types/Activity'

export class CaseActivityModule extends HttpFactory {
  protected $events = {}

  async list(caseId: Case['id'], params: PaginatedParams) {
    return await this.call<Activity[]>('get', `/cases/${caseId}/activity`, params)
  }
}
