import type { PaginatedParams, SearchableParams, SortableParams } from '../factory'
import HttpFactory from '../factory'

export interface Contact {
  id: string
  full_name: string
  type: string
  is_default: boolean
  company: string
  phone: string
  fax: string
  address_line_1?: string
  address_line_2?: string
  city?: string
  state?: string
  postal_code?: string
  email?: string
}

export class ContactsModule extends HttpFactory {
  protected $events = {}

  async list(params: PaginatedParams & SortableParams & SearchableParams) {
    return await this.call<Contact[]>('get', '/contacts', params)
  }

  async get(contactId: Contact['id']) {
    return await this.call<Contact>('get', `/contacts/${contactId}`)
  }
}
