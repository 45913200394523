import axios from 'axios'
import {
  AccountsModule,
  AllowedCategoriesModule,
  AttorneysModule,
  AuthModule,
  BuildingsModule,
  CasesModule,
  CommunitiesModule,
  ContactsModule,
  DashboardModule,
  FirmsModule,
  IntegrationsModule,
  JudgesModule,
  LeasesModule,
  NotificationsModule,
  OrganizationModule,
  PaymentAccountsModule,
  PermissionsModule,
  ReportsModule,
  ResidentsModule,
  ResourcesModule,
  RolesModule,
  SearchModule,
  TooltipsModule,
  UnitsModule,
  UsersModule,
  WorkflowModule,
} from '~/repository/modules'
import {CourtsModule} from "~/repository/modules/courts";
import {DocumentsModule} from "~/repository/modules/documents";

export interface IApiInstance {
  accounts: AccountsModule
  allowedCategories: AllowedCategoriesModule
  attorneys: AttorneysModule
  auth: AuthModule
  buildings: BuildingsModule
  cases: CasesModule
  communities: CommunitiesModule
  contacts: ContactsModule
  courts: CourtsModule
  dashboard: DashboardModule
  documents: DocumentsModule
  firms: FirmsModule
  integrations: IntegrationsModule
  judges: JudgesModule
  leases: LeasesModule
  notifications: NotificationsModule
  organization: OrganizationModule
  paymentAccounts: PaymentAccountsModule
  permissions: PermissionsModule
  reports: ReportsModule
  resources: ResourcesModule
  roles: RolesModule
  search: SearchModule
  residents: ResidentsModule
  tooltips: TooltipsModule
  units: UnitsModule
  users: UsersModule
  workflow: WorkflowModule
}


export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig()

  const $axios = axios.create({
    baseURL: runtimeConfig.public.baseURL,
    headers: {
      'T-Domain': window.location.hostname,
    },
  })

  if (process.env.NODE_ENV === 'development') {
    // Add a request interceptor to include the XDEBUG_SESSION parameter
    $axios.interceptors.request.use(config => {
      config.params = {
        ...config.params,
        XDEBUG_TRIGGER: 'PHPSTORM',
      };
      return config;
    });
  }

  const modules: IApiInstance = {
    accounts: new AccountsModule($axios),
    allowedCategories: new AllowedCategoriesModule($axios),
    attorneys: new AttorneysModule($axios),
    auth: new AuthModule($axios),
    buildings: new BuildingsModule($axios),
    cases: new CasesModule($axios),
    communities: new CommunitiesModule($axios),
    contacts: new ContactsModule($axios),
    courts: new CourtsModule($axios),
    dashboard: new DashboardModule($axios),
    documents: new DocumentsModule($axios),
    firms: new FirmsModule($axios),
    integrations: new IntegrationsModule($axios),
    judges: new JudgesModule($axios),
    leases: new LeasesModule($axios),
    notifications: new NotificationsModule($axios),
    organization: new OrganizationModule($axios),
    paymentAccounts: new PaymentAccountsModule($axios),
    permissions: new PermissionsModule($axios),
    reports: new ReportsModule($axios),
    resources: new ResourcesModule($axios),
    roles: new RolesModule($axios),
    search: new SearchModule($axios),
    residents: new ResidentsModule($axios),
    tooltips: new TooltipsModule($axios),
    units: new UnitsModule($axios),
    users: new UsersModule($axios),
    workflow: new WorkflowModule($axios),
  }

  return {
    provide: {
      api: modules,
    },
  }
})
