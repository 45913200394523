import type { AxiosInstance } from 'axios'
import { IntegrationPlatformsModule } from './platforms'
import type { InputType } from '~/ui/components/PolymorphicInput/PolymorphicInput.types'
import type { Organization } from '~/repository/modules'
import type { PaginatedParams, SearchableParams, SortableParams } from '~/repository/factory'
import HttpFactory from '~/repository/factory'
import { IntegrationLogsModule } from '~/repository/modules/integrations/logs'
import type { ComboboxOption } from '~/ui/components/Combobox/BaseCombobox.vue'

export enum RequestParamLocation {
  Body = 'body',
  Query = 'query',
  Header = 'header',
  Auth = 'auth',
}

export interface CapabilityParam {
  required: boolean
  type: InputType
  dependsOn?: string
  description?: string
  items?: Array<ComboboxOption>
  default?: unknown
  location?: RequestParamLocation
}

export interface CapabilityParams {
  [key: string]: CapabilityParam
}

export interface CapabilityArgs {
  [key: string]: any
}

export interface Capability {
  key: string
  label: string
  description: string
  params: Array<CapabilityParam>
}

export interface IntegrationPlatform {
  id: string
  name: string
  description: string
  capabilities: Capability[]
  supported_endpoint_types: IntegrationEndpointType[]
  auth_types?: IntegrationAuthTypes
  default_base_urls?: IntegrationBaseUrl
  default_credentials?: IntegrationCredential
  default_metadata?: IntegrationMetadata
}

export interface IntegrationBaseUrl {
  [key: string]: string
}

export interface IntegrationMetadata {
  [key: string]: string
}

export interface IntegrationEndpointType {
  key: string
  label: string
}

export interface IntegrationAuthTypes {
  [key: string]: IntegrationAuthTypeEntry
}

export interface IntegrationAuthTypeEntry {
  key?: string
  label?: string
  type: string
}

export interface IntegrationCredential {
  [key: string]: {
    [key: string]: string
  }
}

export interface Integration {
  id: string
  organization: Organization
  platform: IntegrationPlatform
  credentials: Array<unknown>
  metadata: Array<unknown>
  base_urls: Array<Array<string>>
  initial_sync: Date | null
  two_stage_auth: Boolean
}

export class IntegrationsModule extends HttpFactory {
  logs: IntegrationLogsModule
  platforms: IntegrationPlatformsModule
  protected $events = {}

  constructor(ax: AxiosInstance) {
    super(ax)
    this.logs = new IntegrationLogsModule(ax)
    this.platforms = new IntegrationPlatformsModule(ax)
  }

  async list(params: SearchableParams & PaginatedParams & SortableParams) {
    return await this.call<Integration[]>('get', '/integrations', params)
  }

  async get(id: Integration['id']) {
    return await this.call<Integration>('get', `/integrations/${id}`)
  }

  async create(data: Partial<Integration>) {
    return await this.call<Integration>('post', '/integrations', {}, data)
  }

  async update(id: Integration['id'], data: Partial<Integration>) {
    return await this.call<Integration>('put', `/integrations/${id}`, {}, data)
  }

  async remove(id: Integration['id']) {
    return await this.call<Integration>('delete', `/integrations/${id}`)
  }

  async callAction(id: Integration['id'], params: any, data: Record<string, unknown>) {
    return await this.call<Integration>('post', `/integrations/${id}/call-action`, params, data)
  }

  async syncAll(id: Integration['id'], params?: any) {
    return await this.call<Integration>('post', `/integrations/${id}/sync-all`, params)
  }

  async dailySync(id: Integration['id']) {
    return await this.call<Integration>('post', `/integrations/${id}/daily-sync`)
  }

  async checkAuth(id: Integration['id']) {
    return await this.call<Integration>('get', `/integrations/${id}/check-auth`)
  }

  async getCapabilityParamDefinitions(id: Integration['id'], capability: Capability['key'], capabilityArgs?: CapabilityArgs) {
    const queryParams = new URLSearchParams(capabilityArgs)

    return await this.call<CapabilityParams>('get', `/integrations/${id}/capabilities/${capability}/param-definitions?${queryParams.toString()}`)
  }
}

export * from './logs'
export * from './platforms'
