import type { EventHook } from '@vueuse/core'
import type { RequestEventHookMap, RequestEvents } from '../factory'
import HttpFactory from '../factory'

export interface Tooltip {
  id: string
  key: string
  content: string
}

export interface TooltipHookMap extends RequestEventHookMap {
  created: Tooltip
  updated: Tooltip
}

export interface TooltipEvents extends RequestEvents {
  created: EventHook<TooltipHookMap['created']>
  updated: EventHook<TooltipHookMap['updated']>
}

export class TooltipsModule extends HttpFactory<TooltipHookMap, TooltipEvents> {
  protected $events: TooltipEvents = {
    created: createEventHook(),
    updated: createEventHook(),
  }

  async list() {
    return await this.call<Tooltip[]>('get', '/tooltips')
  }

  async create(params: Pick<Tooltip, 'content' | 'key'>) {
    const response = await this.call<Tooltip>('post', '/tooltips', params)

    await this.$events.created.trigger(response.data)

    return response
  }

  async update(id: Tooltip['id'], params: Pick<Tooltip, 'content' | 'key'>) {
    const response = await this.call<Tooltip>('post', `/tooltips/${id}`, params)

    await this.$events.updated.trigger(response.data)

    return response
  }
}
