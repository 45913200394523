import type { EventHook } from '@vueuse/core'
import type { RequestEventHookMap, RequestEvents } from '../../factory'
import HttpFactory from '../../factory'
import type { Case } from './'

export interface CourtDate {
  id: string
  date: string
  time: string
  created_at: string
  updated_at: string
}

export type UpsertCourtDate = Pick<CourtDate, 'date' | 'time'>

export interface CourtDateHookMap extends RequestEventHookMap {
  created: { caseId: Case['id']; courtDate: CourtDate }
  updated: { caseId: Case['id']; courtDate: CourtDate }
}

export interface CourtDateEvents extends RequestEvents {
  created: EventHook<CourtDateHookMap['created']>
  updated: EventHook<CourtDateHookMap['updated']>
}

export class CaseCourtDateModule extends HttpFactory<CourtDateHookMap, CourtDateEvents> {
  protected $events: CourtDateEvents = {
    created: createEventHook(),
    updated: createEventHook(),
  }

  async create(caseId: Case['id'], params: Pick<UpsertCourtDate, 'date'>) {
    const response = await this.call<CourtDate>('post', `/cases/${caseId}/court-date`, params)

    await this.$events.created.trigger({
      caseId,
      courtDate: response.data,
    })

    return response
  }

  async update(caseId: Case['id'], courtDateId: CourtDate['id'], params: Partial<UpsertCourtDate>) {
    const response = await this.call<CourtDate>('post', `/cases/${caseId}/court-date/${courtDateId}`, params)

    await this.$events.updated.trigger({
      caseId,
      courtDate: response.data,
    })

    return response
  }
}
