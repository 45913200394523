import type { EventHook } from '@vueuse/core'
import type { RequestEventHookMap, RequestEvents } from '../../factory'
import HttpFactory from '../../factory'
import type { Case } from '.'

export interface CaseEvictionTime { hours: number; seconds: number; minutes: number }

export interface SaveChangeEvictionDateData {
  case_ids: Array<Case['id']>
  eviction_date: string | Date
}

export interface CaseChangeEvictionDateHookMap extends RequestEventHookMap {
  saved: undefined
}

export interface CaseChangeEvictionDateEvents extends RequestEvents {
  saved: EventHook<CaseChangeEvictionDateHookMap['saved']>
}

export class CaseChangeEvictionDateModule extends HttpFactory<CaseChangeEvictionDateHookMap, CaseChangeEvictionDateEvents> {
  protected $events = {
    saved: createEventHook(),
  }

  async save(params: SaveChangeEvictionDateData) {
    const response = await this.call('post', '/cases/change-eviction-date', params)

    await this.$events.saved.trigger(undefined)

    return response
  }
}
