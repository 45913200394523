import HttpFactory from '../factory'

export interface Statistics {
  communities_count: number
  buildings_count: number
  units_count: number
  leases_count: number
  cases_count: number
  reports_count: number
}

export class DashboardModule extends HttpFactory {
  protected $events = {}

  async statistics() {
    return await this.call<Statistics>('get', '/dashboard/statistics')
  }
}
