import type { SearchableParams } from '../factory'
import HttpFactory from '../factory'

export interface Search {
  cases: number
  archivedCases: number
  units: number
  leases: number
  residents: number
  contacts: number
  total: number
}

export interface SearchFilterData {
  communities: Array<string>
  workflow_steps: Array<string>
  dc_cv_115_rent_due_for: Array<string>
  counties: Array<string>,
  lease_start_dates: Array<string>,
  lease_end_dates: Array<string>,
  contact_types: Array<string>,
  contact_companies: Array<string>,
}

export class SearchModule extends HttpFactory {
  protected $events = {}

  async get(params: SearchableParams) {
    return await this.call<Search>('get', '/search', params)
  }

  async searchFilters(params: any) {
    return await this.call<SearchFilterData>('get', `/search/filters`, params)
  }
}
