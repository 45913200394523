import { DateTime } from 'luxon'

export function useDate() {
  function generateTimestamp(seconds: number) {
    const now = DateTime.local()
    return now.plus({ seconds })
  }

  function fromISO(date: string) {
    return DateTime.fromISO(date)
  }

  function toString(date: string | null | undefined | Date) {
    if (!date)
      return ''

    if (date instanceof Date)
      return DateTime.fromJSDate(date).toLocaleString()

    return DateTime.fromISO(date).toLocaleString()
  }

  function toISOString(date: string) {
    return DateTime.fromISO(date).toISODate()
  }

  function toStringWithTime(date: string) {
    return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT)
  }

  function to24TimeString(time: string) {
    return DateTime.fromFormat(time, 'HH:mm:ss').toFormat('H:mm')
  }

  function toYYYYMMDDFormat(dateString: Date) {
    return DateTime.fromJSDate(dateString).toFormat('yyyy-MM-dd')
  }

  function getOrdinal(day?: number) {
    if (day === null || day === undefined)
      return ''

    const s = ['th', 'st', 'nd', 'rd']
    const v = day % 100
    return day + (s[(v - 20) % 10] || s[v] || s[0])
  }

  return {
    generateTimestamp,
    fromISO,
    toString,
    toISOString,
    to24TimeString,
    toStringWithTime,
    toYYYYMMDDFormat,
    getOrdinal,
  }
}
