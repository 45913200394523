import { CheckCircleIcon, MinusCircleIcon } from '@heroicons/vue/20/solid'
import moment from 'moment'
import Badge from '~/ui/components/Badge/Badge.vue'

export function useCommonHelpers() {
  function renderYesNoBadge(value: boolean, color = true) {
    if (value) {
      return h('span', { class: 'space-x-2 whitespace-nowrap' }, [
        h(color ? CheckCircleIcon : MinusCircleIcon, {
          class: color
            ? 'text-green-500 h-4 w-4 inline'
            : 'text-red-500 h-4 w-4 inline',
        }),
        h('span', 'Yes'),
      ])
    }

    return h('span', { class: 'space-x-2 whitespace-nowrap' }, [
      h(!color ? CheckCircleIcon : MinusCircleIcon, {
        class: !color
          ? 'text-green-500 h-4 w-4 inline'
          : 'text-red-500 h-4 w-4 inline',
      }),
      h('span', 'No'),
    ])
  }

  function renderBadge(value: string | null | undefined, type: string) {
    if (!value)
      return h('span', null)

    return h(Badge as never, { type, class: 'whitespace-nowrap' }, () => value)
  }

  function renderShorten(value: string | null | undefined, length: number) {
    if (value == null)
      return renderNull()

    const str = (value.length > length && length !== 0)
      ? `${value.slice(0, length - 1)}...`
      : value

    return `<span>${str}</span>`
  }

  // #region dates

  function formatDate(value: string | null | undefined) {
    if (value == null)
      return ''

    return moment(value).format('MM/DD/YYYY')
  }

  function formatTime(value: string | null | undefined) {
    if (value == null)
      return ''

    return moment(value).format('hh:mm A')
  }

  function formatDateTime(value: string | null | undefined) {
    if (value == null)
      return ''

    return moment(value).format('MM/DD/YYYY hh:mm A')
  }

  function renderDate(value: string | null | undefined) {
    const v = formatDate(value)

    return h('span', v)
  }

  function renderTime(value: string | null | undefined) {
    const v = formatTime(value)

    return h('span', v)
  }

  function renderDateTime(value: string | null | undefined) {
    const v = formatDateTime(value)

    return h('span', v)
  }
  // #endregion

  // #region money
  function formatMoney(value: number | null | undefined) {
    let v = ''

    if (value !== null && value !== undefined) {
      v = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(value)
    }

    return v
  }

  function renderMoney(value: number | null, className = '') {
    const v = formatMoney(value)
    return h('span', { class: className }, v)
  }
  // #endregion

  function formatFileSize(value: number | null | undefined) {
    if (value == null)
      return ''

    let size = value
    const units = ['B', 'KB', 'MB', 'GB', 'TB']
    let unitIndex = 0

    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024
      unitIndex++
    }

    return Math.round(size * 10) / 10 + units[unitIndex]
  }

  function renderFileSize(value: number | null, className = '') {
    const v = formatFileSize(value)
    return h('span', { class: className }, v)
  }

  function renderNull() {
    return ''
  }

  function formatFileExtensionName(value: string) {
    let name = value

    switch (value) {
      case 'pdf':
        name = 'PDF'
        break
      case 'docx':
        name = 'Word'
        break
      case 'xlsx':
        name = 'Excel'
        break
      case 'pptx':
        name = 'PowerPoint'
        break
      case 'zip':
        name = 'Zip'
        break
    }

    return name
  }

  function renderFileExtensionName(value: string) {
    const v = formatFileExtensionName(value)
    return h('span', v)
  }

  return {
    renderYesNoBadge,
    renderBadge,
    renderShorten,

    formatDate,
    renderDate,

    formatTime,
    renderTime,

    formatDateTime,
    renderDateTime,

    formatMoney,
    renderMoney,

    formatFileSize,
    renderFileSize,

    formatFileExtensionName,
    renderFileExtensionName,
  }
}
