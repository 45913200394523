import type { EventHook } from '@vueuse/core'
import type { PaginatedParams, RequestEventHookMap, RequestEvents, SearchableParams, SortableParams } from '../factory'
import HttpFactory from '../factory'

export interface AllowedCategory {
  id: string
  name: string
}

export interface AllowedCategoriesHookMap extends RequestEventHookMap {
  created: AllowedCategory
}

export interface AllowedCategoriesEvents extends RequestEvents {
  created: EventHook<AllowedCategoriesHookMap['created']>
}

export class AllowedCategoriesModule extends HttpFactory<AllowedCategoriesHookMap, AllowedCategoriesEvents> {
  protected $events = {
    created: createEventHook(),
  }

  async list(params: PaginatedParams & SortableParams & SearchableParams) {
    return await this.call<AllowedCategory[]>('get', '/allowed-categories', params)
  }

  async create(data: AllowedCategory) {
    const response = await this.call<AllowedCategory>('post', '/allowed-categories', undefined, data)

    await this.$events.created.trigger(response.data)

    return response
  }
}
