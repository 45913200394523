import type { PaginatedParams, SearchableParams } from '../factory'
import HttpFactory from '../factory'
import type { Unit } from './units'

export interface CourtAvailability {
  [key: string]: string
}

export interface Court {
  id: string
  name: string
  county: string
  address_line_1: string
  address_line_2: string | null
  city: string
  state: string
  postal_code: string
  availability: Array<CourtAvailability>
  notice_period: number
  fee_base: number
  fee_per_tenant: number
  fee_wor: number
}

export class CourtsModule extends HttpFactory {
  protected $events = {}

  async list(params: PaginatedParams & SearchableParams) {
    return this.call<Court[]>('get', '/courts', params)
  }

  async get(id: Court['id']) {
    return this.call<Court>('get', `/courts/${id}`)
  }

  async getAvailability(id: Court['id']) {
    return this.call<CourtAvailability[]>('get', `/courts/${id}/availability`)
  }

  async getUnits(id: Court['id'], params: PaginatedParams & SearchableParams) {
    return this.call<Unit[]>('get', `/courts/${id}/units`, params)
  }
}
