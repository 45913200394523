import HttpFactory, { IMessageResponse } from '../factory'

export interface OrganizationChartOfAccounts {
  id: string
  gl_account: string
  account: string
  description?: string
  use_for_case_calculations: boolean
  created_at: string
}

export type OrganizationChartOfAccountsUpdate = Pick<OrganizationChartOfAccounts, 'id' | 'gl_account' | 'use_for_case_calculations'>[]

export interface InternalGlAccount {
  account: string
  description: string
}

export interface AccountsForMapping {
  gl_accounts: InternalGlAccount[]
  organization_chart_of_accounts: OrganizationChartOfAccounts[]
}

export class AccountsModule extends HttpFactory {
  protected $events = {}

  async list() {
    return await this.call<OrganizationChartOfAccounts[]>(
      'get',
      '/settings/organization-chart-of-accounts',
    )
  }

  async listForMapping() {
    return await this.call<AccountsForMapping>(
      'get',
      '/settings/accounts-for-mapping',
    )
  }

  async updateMapping(data: OrganizationChartOfAccountsUpdate) {
    const { user } = useAuthStore()

    return await this.call<IMessageResponse>(
      'put',
      '/settings/chart-of-accounts',
      {},
      {
        organization_id: user?.organization_id,
        organization_chart_of_accounts: data,
      },
    )
  }
}
