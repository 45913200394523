import type { AxiosInstance } from 'axios'
import type { EventHook } from '@vueuse/core'
import type { RequestEventHookMap, RequestEvents } from '../factory'
import HttpFactory from '../factory'

export interface PaymentAccount {
  id: string
  origin_id: string
  origin_platform: string
  type: PaymentAccountType
  firm_id: string
  name: string
  token: string
  card_type: string
  card_expiration: string
  card_holder_name: string
  card_last_four: string
  card_month: string
  card_year: string
  active: string
  is_available_at_all_locations: string
  updated_at: string
}
export interface PaymentAccountType {
  code: string
  description: string
}

export interface PaymentAccountHookMap extends RequestEventHookMap {
  updated: PaymentAccount
}

export interface PaymentAccountEvents extends RequestEvents {
  updated: EventHook<PaymentAccount>
}

export class PaymentAccountsModule extends HttpFactory<PaymentAccountHookMap, PaymentAccountEvents> {
  protected $events = {
    updated: createEventHook(),
  }

  constructor(ax: AxiosInstance) {
    super(ax)
  }

  async list() {
    return await this.call<PaymentAccount[]>('get', '/payment-accounts')
  }

  async getById(id: PaymentAccount['id']) {
    return await this.call<PaymentAccount>('get', `/payment-accounts/${id}`)
  }
}
