import type { EventHook } from '@vueuse/core'
import type { RequestEventHookMap, RequestEvents } from '../../factory'
import HttpFactory from '../../factory'
import type { Case } from '.'

export interface SaveEvictionWizardData {
  case_ids: Array<Case['id']>
  move_to_next: boolean | number
  eviction_date: string | Date
  generate_calendar_event: boolean
}

export interface CaseEvictionWizardHookMap extends RequestEventHookMap {
  saved: undefined
}

export interface CaseEvictionWizardEvents extends RequestEvents {
  saved: EventHook<CaseEvictionWizardHookMap['saved']>
}

export class CaseEvictionWizardModule extends HttpFactory<CaseEvictionWizardHookMap, CaseEvictionWizardEvents> {
  protected $events = {
    saved: createEventHook(),
  }

  async save(params: SaveEvictionWizardData) {
    const response = await this.call('post', '/cases/store-eviction-wizard', params)

    await this.$events.saved.trigger(undefined)

    return response
  }
}
