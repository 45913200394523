import type { PaginatedParams, SearchableParams, SortableParams } from '../factory'
import HttpFactory from '../factory'

export interface CaseReport {
  id: string
  location: string
  created_at: string
}

export interface CommonReport {
  id: string
  name: string
  location: string
  created_at: string
}

export class ReportsModule extends HttpFactory {
  protected $events = {}

  async cases(params: PaginatedParams & SortableParams & SearchableParams) {
    return await this.call<CaseReport[]>('get', '/reports/cases', params)
  }

  async casesDownloadLink(params: { location: string }) {
    return await this.call<string>('get', '/reports/cases/download-link', params)
  }

  async dccv115() {
    return await this.call<CommonReport[]>('get', 'reports/dccv115')
  }

  async downloadActiveCase() {
    return this.downloadFile<Blob>('post', '/reports/cases/active')
  }

  async evictionNotice() {
    return await this.call<CommonReport[]>('get', 'reports/eviction-notice')
  }
}
