import HttpFactory from '../../factory'
import type {Case} from './'
import {PDFCheckBox, PDFDocument, PDFTextField} from 'pdf-lib';

export interface Case115TrackingItem {
  id: string
  barcode: string
  delivery_address: string
  drop_date: string
  drop_facility: string
  estimated_delivery_date: string
  last_scan_date: string
  last_scan_location: string
  number_of_business_days_for_delivery: number
  routing_zip: string
  created_at: string
}

interface PDFPreviewResponse {
  pdf: ArrayBuffer;
  formData: Record<string, string>;
}

export class Case115TrackingModule extends HttpFactory {
  protected $events = {}

  async list(caseId: Case['id']) {
    return await this.call<Case115TrackingItem[]>('get', `/cases/${caseId}/115/tracking`)
  }

  async preview115(caseId: Case['id']): Promise<Blob> {
    try {
      const dataResponse = await this.call<PDFPreviewResponse>('get', `/cases/${caseId}/115/dccv115-preview`);

      const data = dataResponse.data;
      const pdfBytes = data.pdf;
      const formData = data.formData;

      const pdfDoc = await PDFDocument.load(pdfBytes);

      const form = pdfDoc.getForm();

      for (const key in formData) {
        const field = form.getFieldMaybe(key); // Only proceed if field exists

        if (field && field instanceof PDFTextField) {
          field.setText(formData[key]);
        } else if (field && field instanceof PDFCheckBox) {
          const isChecked = formData[key] === 'Yes';
          isChecked ? field.check() : field.uncheck();
        } else {
          console.warn(`Field ${key} not found or unsupported type in the PDF form`);
        }
      }

      form.flatten();

      const modifiedPdfBytes = await pdfDoc.save();

      return new Blob([modifiedPdfBytes], {type: 'application/pdf'});
    } catch (error) {
      console.error('Error generating stamped PDF:', error);
      throw error;
    }
  }
}
