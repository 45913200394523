import type { AxiosError } from 'axios'
import type { FormActions, GenericObject, Path } from 'vee-validate'
import type { AxiosFormError } from '~/repository/factory'

function transformKey(key: string) {
  if (key.includes('.'))
    return key.replace(/(\w+)\.(\d+)\.(\w+)/g, '$1[$2].$3')

  else
    return key
}

export function isAxiosError(error: any): error is AxiosError {
  return 'isAxiosError' in error && error.isAxiosError === true
}

export function isAxiosFormError(error: any): error is AxiosFormError {
  return isAxiosError(error) && error.response?.status === 422
}

export function setFormErrorsFromRequest<T extends GenericObject>(e: AxiosFormError, setFieldError: FormActions<T>['setFieldError']) {
  Object.keys(e.response?.data.errors as object).forEach((key) => {
    setFieldError(transformKey(key) as Path<T>, e.response?.data.errors[key])
  })
}
