import type { EventHook } from '@vueuse/core'
import type { RequestEventHookMap, RequestEvents } from '../../factory'
import HttpFactory from '../../factory'
import type { Organization } from '../organization'

export interface ImportChartOfAccountsSheet {
  organizationId: string
  sheet: File
}

export interface SheetHookMap extends RequestEventHookMap {
  imported: Organization
}

export interface SheetEvents extends RequestEvents {
  imported: EventHook<SheetHookMap['imported']>
}

export class OrganizationChartOfAccountsModule extends HttpFactory<SheetHookMap, SheetEvents> {
  protected $events = {
    imported: createEventHook(),
  }

  async import(params: ImportChartOfAccountsSheet) {
    const formData = new FormData()
    formData.append('sheet', params.sheet)
    formData.append('organization_id', params.organizationId)

    const response = await this.call('post', `/settings/chart-of-accounts/import`, undefined, formData)

    await this.$events.imported.trigger(response.data)

    return response
  }
}
