import { default as forgot_45passwordFVUV1uZPCiMeta } from "/buddy/apps/rcm-frontend/sites/auth/pages/auth/forgot-password.vue?macro=true";
import { default as loginwOi9rJJ4tMMeta } from "/buddy/apps/rcm-frontend/sites/auth/pages/auth/login.vue?macro=true";
import { default as register6Fbr9BfyStMeta } from "/buddy/apps/rcm-frontend/sites/auth/pages/auth/register.vue?macro=true";
import { default as reset_45passwords31e3h4yZLMeta } from "/buddy/apps/rcm-frontend/sites/auth/pages/auth/reset-password.vue?macro=true";
import { default as indexWP0b9nDplGMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/buildings/[building]/index.vue?macro=true";
import { default as _91building_9381RbnTlv93Meta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/buildings/[building].vue?macro=true";
import { default as indexhB5TbnZlvVMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/buildings/index.vue?macro=true";
import { default as buildingsinSh3OCKpAMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/buildings.vue?macro=true";
import { default as indexzYVqKkTSLSMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/cases/[case_id]/index.vue?macro=true";
import { default as _91case_id_93JxCN48Mu24Meta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/cases/[case_id].vue?macro=true";
import { default as index6EbmsrqLRNMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/cases/processing-queue/index.vue?macro=true";
import { default as processing_45queuesh8MufZf7AMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/cases/processing-queue.vue?macro=true";
import { default as indexwgcJMq3NjUMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/cases/step/[step_id]/index.vue?macro=true";
import { default as _91step_id_93YwaABqKTJYMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/cases/step/[step_id].vue?macro=true";
import { default as stepzOFziF9M35Meta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/cases/step.vue?macro=true";
import { default as cases85vh8qFZiOMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/cases.vue?macro=true";
import { default as index1BvN21vNe0Meta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/communities/[community]/index.vue?macro=true";
import { default as _91community_93x7J1mmMNSeMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/communities/[community].vue?macro=true";
import { default as index8yx1tgCaoLMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/communities/index.vue?macro=true";
import { default as communitiesrTJdWIqOhyMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/communities.vue?macro=true";
import { default as indexe90FfpUF0dMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/dashboard/index.vue?macro=true";
import { default as indexMLVeQtdXP2Meta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/documents/index.vue?macro=true";
import { default as documentsZHxid3WEy4Meta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/documents.vue?macro=true";
import { default as indexPJ6SmQRFaNMeta } from "/buddy/apps/rcm-frontend/sites/landing/pages/index.vue?macro=true";
import { default as editVsE0G8aJstMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/integrations/[id]/edit.vue?macro=true";
import { default as indexaYbSQtla2NMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/integrations/[id]/index.vue?macro=true";
import { default as _91id_93K36xQGtAvKMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/integrations/[id].vue?macro=true";
import { default as indexqD12FydZUzMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/integrations/create/index.vue?macro=true";
import { default as index3m91Yshk88Meta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/integrations/index.vue?macro=true";
import { default as integrationsfU3B24vPCMMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/integrations.vue?macro=true";
import { default as indexk2b1c4IcKJMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/leases/[lease_id]/index.vue?macro=true";
import { default as _91lease_id_9347tMiCPoRVMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/leases/[lease_id].vue?macro=true";
import { default as indexvH7cxnav3RMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/leases/index.vue?macro=true";
import { default as leasesBntHNgAtlDMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/leases.vue?macro=true";
import { default as index5RW9PcodgwMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/logs/error/index.vue?macro=true";
import { default as indexKYpIAOiagXMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/logs/integration/[id]/index.vue?macro=true";
import { default as _91id_93owTdeA198FMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/logs/integration/[id].vue?macro=true";
import { default as indexJ5AOe6drhDMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/logs/integration/index.vue?macro=true";
import { default as logsBTzj0B1lk5Meta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/logs.vue?macro=true";
import { default as case_45automationsKUGyYdVBLQMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/organization/settings/case-automations.vue?macro=true";
import { default as case_45filingswYCTzjMDsFMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/organization/settings/case-filings.vue?macro=true";
import { default as chart_45of_45accountsg2n4Iztr5bMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/organization/settings/chart-of-accounts.vue?macro=true";
import { default as demo_45settingsPyMKCjW93RMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/organization/settings/demo-settings.vue?macro=true";
import { default as e_45file_45settings7ioUmptP4vMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/organization/settings/e-file-settings.vue?macro=true";
import { default as index10VqcMkBXmMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/organization/settings/index.vue?macro=true";
import { default as lease_45date_45configurationsdtUMYQmvV6Meta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/organization/settings/lease-date-configurations.vue?macro=true";
import { default as multi_45step_45processingcCXsfklsvbMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/organization/settings/multi-step-processing.vue?macro=true";
import { default as roles_45and_45permissionsapZZMeekUwMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/organization/settings/roles-and-permissions.vue?macro=true";
import { default as organizationytGwLBO8QjMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/organization.vue?macro=true";
import { default as index6B6HJJlqdZMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/profile/index.vue?macro=true";
import { default as profileG7pL1fqbkdMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/profile.vue?macro=true";
import { default as propertiesbMZdLRDt6aMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/properties.vue?macro=true";
import { default as indexXm2mmDL3PhMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/reports/index.vue?macro=true";
import { default as reportsPLYjAuNZp4Meta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/reports.vue?macro=true";
import { default as indexo8SXt3KGJWMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/search/index.vue?macro=true";
import { default as searchiLgFqIwYGLMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/search.vue?macro=true";
import { default as indexdkhC1mgeeOMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/settings/index.vue?macro=true";
import { default as settingswS8MXsbLIyMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/settings.vue?macro=true";
import { default as indexXSbyiRQHetMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/tooltips/index.vue?macro=true";
import { default as indexh2OD1CEPjaMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/units/[unit]/index.vue?macro=true";
import { default as _91unit_93hacKH3Gl54Meta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/units/[unit].vue?macro=true";
import { default as indexYWWxS078zZMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/units/index.vue?macro=true";
import { default as unitsv6gg89EqJ6Meta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/units.vue?macro=true";
import { default as editNFJZzsaY5WMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/users/[id]/edit.vue?macro=true";
import { default as indexegMxIuBhKAMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/users/[id]/index.vue?macro=true";
import { default as _91id_93bmorR6P5FMMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/users/[id].vue?macro=true";
import { default as createhu9QFDcrbpMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/users/create.vue?macro=true";
import { default as index02KQ7a0W4jMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/users/index.vue?macro=true";
import { default as userswyRN1SIQdDMeta } from "/buddy/apps/rcm-frontend/sites/dashboard/pages/users.vue?macro=true";
export default [
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordFVUV1uZPCiMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/auth/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginwOi9rJJ4tMMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/auth/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: register6Fbr9BfyStMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/auth/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password",
    path: "/auth/reset-password",
    meta: reset_45passwords31e3h4yZLMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/auth/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: buildingsinSh3OCKpAMeta?.name,
    path: "/buildings",
    meta: buildingsinSh3OCKpAMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/buildings.vue").then(m => m.default || m),
    children: [
  {
    name: _91building_9381RbnTlv93Meta?.name,
    path: ":building()",
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/buildings/[building].vue").then(m => m.default || m),
    children: [
  {
    name: "buildings-building",
    path: "",
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/buildings/[building]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "buildings",
    path: "",
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/buildings/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cases",
    path: "/cases",
    meta: cases85vh8qFZiOMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/cases.vue").then(m => m.default || m),
    children: [
  {
    name: _91case_id_93JxCN48Mu24Meta?.name,
    path: ":case_id()",
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/cases/[case_id].vue").then(m => m.default || m),
    children: [
  {
    name: "cases-case_id",
    path: "",
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/cases/[case_id]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: processing_45queuesh8MufZf7AMeta?.name,
    path: "processing-queue",
    meta: processing_45queuesh8MufZf7AMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/cases/processing-queue.vue").then(m => m.default || m),
    children: [
  {
    name: "cases-processing-queue",
    path: "",
    meta: index6EbmsrqLRNMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/cases/processing-queue/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cases-step",
    path: "step",
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/cases/step.vue").then(m => m.default || m),
    children: [
  {
    name: _91step_id_93YwaABqKTJYMeta?.name,
    path: ":step_id()",
    meta: _91step_id_93YwaABqKTJYMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/cases/step/[step_id].vue").then(m => m.default || m),
    children: [
  {
    name: "cases-step-step_id",
    path: "",
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/cases/step/[step_id]/index.vue").then(m => m.default || m)
  }
]
  }
]
  }
]
  },
  {
    name: communitiesrTJdWIqOhyMeta?.name,
    path: "/communities",
    meta: communitiesrTJdWIqOhyMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/communities.vue").then(m => m.default || m),
    children: [
  {
    name: _91community_93x7J1mmMNSeMeta?.name,
    path: ":community()",
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/communities/[community].vue").then(m => m.default || m),
    children: [
  {
    name: "communities-community",
    path: "",
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/communities/[community]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "communities",
    path: "",
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/communities/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexe90FfpUF0dMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: documentsZHxid3WEy4Meta?.name,
    path: "/documents",
    meta: documentsZHxid3WEy4Meta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/documents.vue").then(m => m.default || m),
    children: [
  {
    name: "documents",
    path: "",
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/documents/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexPJ6SmQRFaNMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/landing/pages/index.vue").then(m => m.default || m)
  },
  {
    name: integrationsfU3B24vPCMMeta?.name,
    path: "/integrations",
    meta: integrationsfU3B24vPCMMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/integrations.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93K36xQGtAvKMeta?.name,
    path: ":id()",
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/integrations/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "integrations-id-edit",
    path: "edit",
    meta: editVsE0G8aJstMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/integrations/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "integrations-id",
    path: "",
    meta: indexaYbSQtla2NMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/integrations/[id]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "integrations-create",
    path: "create",
    meta: indexqD12FydZUzMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/integrations/create/index.vue").then(m => m.default || m)
  },
  {
    name: "integrations",
    path: "",
    meta: index3m91Yshk88Meta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/integrations/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: leasesBntHNgAtlDMeta?.name,
    path: "/leases",
    meta: leasesBntHNgAtlDMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/leases.vue").then(m => m.default || m),
    children: [
  {
    name: _91lease_id_9347tMiCPoRVMeta?.name,
    path: ":lease_id()",
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/leases/[lease_id].vue").then(m => m.default || m),
    children: [
  {
    name: "leases-lease_id",
    path: "",
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/leases/[lease_id]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "leases",
    path: "",
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/leases/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "logs",
    path: "/logs",
    meta: logsBTzj0B1lk5Meta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/logs.vue").then(m => m.default || m),
    children: [
  {
    name: "logs-error",
    path: "error",
    meta: index5RW9PcodgwMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/logs/error/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93owTdeA198FMeta?.name,
    path: "integration/:id()",
    meta: _91id_93owTdeA198FMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/logs/integration/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "logs-integration-id",
    path: "",
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/logs/integration/[id]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "logs-integration",
    path: "integration",
    meta: indexJ5AOe6drhDMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/logs/integration/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "organization",
    path: "/organization",
    meta: organizationytGwLBO8QjMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/organization.vue").then(m => m.default || m),
    children: [
  {
    name: "organization-settings-case-automations",
    path: "settings/case-automations",
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/organization/settings/case-automations.vue").then(m => m.default || m)
  },
  {
    name: "organization-settings-case-filings",
    path: "settings/case-filings",
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/organization/settings/case-filings.vue").then(m => m.default || m)
  },
  {
    name: "organization-settings-chart-of-accounts",
    path: "settings/chart-of-accounts",
    meta: chart_45of_45accountsg2n4Iztr5bMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/organization/settings/chart-of-accounts.vue").then(m => m.default || m)
  },
  {
    name: "organization-settings-demo-settings",
    path: "settings/demo-settings",
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/organization/settings/demo-settings.vue").then(m => m.default || m)
  },
  {
    name: "organization-settings-e-file-settings",
    path: "settings/e-file-settings",
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/organization/settings/e-file-settings.vue").then(m => m.default || m)
  },
  {
    name: "organization-settings",
    path: "settings",
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/organization/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "organization-settings-lease-date-configurations",
    path: "settings/lease-date-configurations",
    meta: lease_45date_45configurationsdtUMYQmvV6Meta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/organization/settings/lease-date-configurations.vue").then(m => m.default || m)
  },
  {
    name: "organization-settings-multi-step-processing",
    path: "settings/multi-step-processing",
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/organization/settings/multi-step-processing.vue").then(m => m.default || m)
  },
  {
    name: "organization-settings-roles-and-permissions",
    path: "settings/roles-and-permissions",
    meta: roles_45and_45permissionsapZZMeekUwMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/organization/settings/roles-and-permissions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profileG7pL1fqbkdMeta?.name,
    path: "/profile",
    meta: profileG7pL1fqbkdMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: "profile",
    path: "",
    meta: index6B6HJJlqdZMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/profile/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "properties",
    path: "/properties",
    meta: propertiesbMZdLRDt6aMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/properties.vue").then(m => m.default || m)
  },
  {
    name: reportsPLYjAuNZp4Meta?.name,
    path: "/reports",
    meta: reportsPLYjAuNZp4Meta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/reports.vue").then(m => m.default || m),
    children: [
  {
    name: "reports",
    path: "",
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/reports/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: searchiLgFqIwYGLMeta?.name,
    path: "/search",
    meta: searchiLgFqIwYGLMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/search.vue").then(m => m.default || m),
    children: [
  {
    name: "search",
    path: "",
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/search/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: settingswS8MXsbLIyMeta?.name,
    path: "/settings",
    meta: settingswS8MXsbLIyMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings",
    path: "",
    meta: indexdkhC1mgeeOMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/settings/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tooltips",
    path: "/tooltips",
    meta: indexXSbyiRQHetMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/tooltips/index.vue").then(m => m.default || m)
  },
  {
    name: unitsv6gg89EqJ6Meta?.name,
    path: "/units",
    meta: unitsv6gg89EqJ6Meta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/units.vue").then(m => m.default || m),
    children: [
  {
    name: _91unit_93hacKH3Gl54Meta?.name,
    path: ":unit()",
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/units/[unit].vue").then(m => m.default || m),
    children: [
  {
    name: "units-unit",
    path: "",
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/units/[unit]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "units",
    path: "",
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/units/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: userswyRN1SIQdDMeta?.name,
    path: "/users",
    meta: userswyRN1SIQdDMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/users.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93bmorR6P5FMMeta?.name,
    path: ":id()",
    meta: _91id_93bmorR6P5FMMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/users/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "users-id-edit",
    path: "edit",
    meta: editNFJZzsaY5WMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/users/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "users-id",
    path: "",
    meta: indexegMxIuBhKAMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/users/[id]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users-create",
    path: "create",
    meta: createhu9QFDcrbpMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/users/create.vue").then(m => m.default || m)
  },
  {
    name: "users",
    path: "",
    meta: index02KQ7a0W4jMeta || {},
    component: () => import("/buddy/apps/rcm-frontend/sites/dashboard/pages/users/index.vue").then(m => m.default || m)
  }
]
  }
]