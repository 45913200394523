export interface Toast {
  type: 'success' | 'danger' | 'warning' | 'info'
  id: number
  timeout: number
  title: string
  message: string
  withButtons: false | Array<{
    name: string
    href: string
  }>
}

export interface ToastOptions {
  title: Toast['title']
  message?: Toast['message']
  timeout?: Toast['timeout']
  withButtons?: Toast['withButtons']
  type?: Toast['type']
}

export interface ToastStore {
  toasts: Ref<Toast[]>
  addToast: (options: ToastOptions) => void
  removeToast: (id: number) => void
}

const toasts = ref<Toast[]>([])

function useDefaultState(): Toast {
  return {
    id: 0,
    timeout: 10000,
    title: '',
    message: '',
    withButtons: false,
    type: 'success',
  }
}

export function useToast(): ToastStore {
  const removeToast = (id: number) => {
    const index = toasts.value.findIndex(toast => toast.id === id)
    if (index !== -1)
      toasts.value.splice(index, 1)
  }

  const addToast = (options: ToastOptions) => {
    const state = useDefaultState()

    Object.keys(options).forEach((o) => {
      const option = options[o as keyof typeof options]
      if (option !== 'undefined')
        (state as any)[o] = option
    })

    state.id = new Date().getTime()

    const toast = reactive<Toast>(state)

    // Push the toast to the list
    toasts.value.push(toast)

    // Remove the toast after the specified timeout
    setTimeout(() => {
      removeToast(state.id)
    }, state.timeout)
  }

  return {
    toasts,
    addToast,
    removeToast,
  }
}
