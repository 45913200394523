import type { EventHook } from '@vueuse/core'
import type { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import { storeToRefs } from 'pinia'

type RequestMethods = 'get' | 'post' | 'put' | 'delete'

export interface ApiResponse<T, P extends PaginationMeta> {
  data: T
  meta: P
  message?: string
  code: number
}

export interface IMessageResponse {
  message: string
}

export type AxiosFormError = AxiosError<{
  message: string
  errors: {
    [key: string]: string[]
  }
}>

export interface RequestEventHookMap {
}

export type RequestEvents<T extends RequestEventHookMap = RequestEventHookMap> =
  {
    [K in keyof T]: EventHook<T[K]>
  }

export interface PaginatedParams {
  page?: number
  per_page?: number
}

export interface FilteredParams {
  filters: Record<string, any>
}

export interface SearchableParams {
  search?: string
}

export interface WhereClauseParams {
  where?: {
    column: string
    operator: string
    value: any
  }[]
}

export interface SortableParams {
  sort?: {
    column: string
    dir: 'asc' | 'desc'
  }
}

export type Params = Record<string, any>

abstract class HttpFactory<
  H extends RequestEventHookMap = RequestEventHookMap,
  E extends RequestEvents<H> = RequestEvents<H>,
> {
  protected $instance: AxiosInstance

  protected abstract $events: E

  constructor(ax: AxiosInstance) {
    this.$instance = ax

    this.$instance.interceptors.request.use((request) => {
      const { token } = storeToRefs(useAuthStore())

      if (token.value)
        request.headers.Authorization = `Bearer ${token.value}`

      request.headers.Accept = 'application/json'

      if (request.data && request.data instanceof FormData)
        request.headers['Content-Type'] = 'multipart/form-data'
      else request.headers['Content-Type'] = 'application/json'

      return request
    })
  }

  protected handleError(e: unknown) {
    if (isAxiosError(e)) {
      if (e.request.status === 401) {
        const authStore = useAuthStore()
        authStore.logout()
        navigateTo('/auth/login')
      }
      else {
        const { $toast } = useNuxtApp()
        const message
          = (e.response?.data as { data: { message: string } })?.data?.message
          || (e.response?.data as { message: string })?.message
          || e.message
          || 'An unknown error occurred'

        $toast.addToast({
          title: 'Error',
          message,
          type: 'danger',
        })
      }
    }
  }

  protected async call<T, P extends PaginationMeta = PaginationMeta>(
    method: RequestMethods,
    url: string,
    params?: object,
    data?: object,
    options: AxiosRequestConfig = {},
  ): Promise<ApiResponse<T, P>> {
    try {
      const response = await this.$instance.request({
        method,
        url,
        params,
        data,
        ...options,
      })

      return { data: response.data.data, code: response.status, message: response.data.data?.message ?? null, meta: response.data?.meta }
    }
    catch (e) {
      this.handleError(e)
      throw e
    }
  }

  protected async downloadFile<T>(
    method: RequestMethods,
    url: string,
    params?: object,
    data?: object,
  ): Promise<T> {
    try {
      const response = await this.$instance.request({
        method,
        url,
        params,
        data,
        responseType: 'blob',
      })

      return response.data
    }
    catch (e) {
      this.handleError(e)
      throw e
    }
  }

  on<K extends keyof H>(key: K | Array<K>, fn: (param: H[K]) => void): void {
    if (Array.isArray(key))
      key.forEach(k => this.$events[k].on(fn as any))
    else this.$events[key].on(fn as any)
  }
}

export default HttpFactory
