import type { PaginatedParams, SearchableParams, SortableParams } from '../../factory'
import HttpFactory from '../../factory'
import type { Lease } from './'

export interface LeaseResident {
  id: string
  full_name: string
  email?: string
  status: string
  financially_responsible?: boolean
  opt_in_for_digital_communication: boolean
  dob: string
  name_on_lease?: boolean
  scra_military_status: boolean
  scra_certificate_id: string
  media: Media<'scra-certificate'>[]
}

export class LeaseResidentsModule extends HttpFactory {
  protected $events = {}

  async list(leaseId: Lease['id'], params: PaginatedParams & SearchableParams & SortableParams) {
    return await this.call<LeaseResident[]>('get', `/leases/${leaseId}/residents`, params)
  }

  async get(leaseId: Lease['id'], residentId: LeaseResident['id']) {
    return await this.call<LeaseResident>('get', `/leases/${leaseId}/residents/${residentId}`)
  }
}
